// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

function RotatingCard({ color, image, title, description, action }) {
  return (
    <MKBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="20px"
      coloredShadow={color}
      position="absolute"
      width="100%"
      height="100%"
      top={0}
      left={0}
      zIndex={5}
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat", 
        backfaceVisibility: "hidden",
        transform: "rotateY(180deg)",
      }}
    >
      <MKBox 
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        pt={3}
        px={2}
        zIndex={1}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          borderRadius:'20px',

        }}
      >
        <MKTypography  sx={{fontSize:{xs:'15px',md:'20px'}}} color="white" gutterBottom>
            {title}
          </MKTypography>
          <MKTypography variant="body2" color="white" opacity={0.8}>
            {description}
          </MKTypography>
          {action && (
            <MKBox width="50%" mt={4} mb={4} mx="auto">
              {action.type === "external" ? (
                <MKButton
                  component={MuiLink}
                  href={action.route}
                  target="_blank"
                  rel="noreferrer"
                  color="success"
                  size="small"
                  fullWidth
                >
                  {action.label}
                </MKButton>
              ) : (
                <MKButton component={MuiLink} href={action.route} rel="noreferrer" color="success" size="small" fullWidth>
                  {action.label}
                </MKButton>
              )}
            </MKBox>
          )}
      </MKBox>
    </MKBox>
  );
}

// Setting default props for the RotatingCard
RotatingCard.defaultProps = {
  color: "info",
};

// Typechecking props for the RotatingCard
RotatingCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

export default RotatingCard;
