import React from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import LoadingIndicator from "components/Loading";

// Venture page sections
import Profile from "./sections/Profile";
import Posts from "./sections/Posts";

// Importa fetchDataVenture
import fetchDataVenture from "infraestructura/api/fetchDataVenture";

// Images
import bgImage from "assets/images/city-profile.jpg";

function Venture() {
  const { id } = useParams();
  const { apiData, loading } = fetchDataVenture.FetchDataVentById(id);

  let imagen = bgImage;

  if (loading) {
    return <LoadingIndicator />;
  }

  if (apiData) {
    if (apiData.imagen) {
      imagen = apiData.imagen;
    }

    return (
      <>
        <MKBox>
          <MKBox
            minHeight="25rem"
            width="100%"
            sx={{
              backgroundImage: ({
                functions: { linearGradient, rgba },
                palette: { gradients },
              }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.5),
                  rgba(gradients.dark.state, 0.5)
                )}, url(${imagen})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          />
          <Card
            sx={{
              p: 2,
              mx: { xs: 2, lg: 3 },
              mt: -8,
              mb: 4,
              backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
                rgba(white.main, 0.8),
              backdropFilter: "saturate(200%) blur(30px)",
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
            <Profile />
            <Posts />
          </Card>
        </MKBox>
      </>
    );
  }

  return <div>No se encontraron datos de perfil.</div>;
}

export default Venture;
