import MKBox from "components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Card, CardContent, Typography } from "@mui/material";
import { Slide, Fade, Zoom } from "react-awesome-reveal";

import PsychologyIcon from "@mui/icons-material/Psychology";
import Diversity3Icon from "@mui/icons-material/Diversity3";
// import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const carouselItems = [
  {
    src: "/images/car1.jpg",
    alt: "Descripción de la imagen 2",
  },
  {
    src: "/images/car2.jpg",
    alt: "Descripción de la imagen 3",
  },
  {
    src: "/images/car3.jpg",
    alt: "Descripción de la imagen 4",
  },
  {
    src: "/images/car4.jpg",
    alt: "Descripción de la imagen 4",
  },
];

export const AboutSection = () => {
  const isMobile = window.innerWidth <= 800;
  return (
    <Container maxWidth="lg" fixed sx={{ py: 1,mb:{xs:'70px',md:'0px'} }} id="AboutSection">
      <Grid container>
        <Grid item container xs={12} lg={6}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{
                fontSize: {
                  xs: "2rem",
                  sm: "2.5rem",
                  md: "2.5rem",
                },
                textAlign: "center",
              }}
            >
              NOSOTROS
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: "0.9rem",
                  sm: "1.1rem",
                  md: "1.1rem",
                  lg: "1rem",
                },
                textAlign: { xs: "center", md: "justify" },
                margin: { xs: "none", md: "30px" },
                marginTop: { xs: "none", md: "0px" }
              }}
              variant="h5"
              mb={2}
            >
              Raíces del bosque es un proyecto en conjunto de mujeres indígenas
              de los pueblos urarina, kukama-kukamiria, bora, ticuna y yagua,
              junto con el Instituto de Investigaciones de la Amazonia Peruana
              (IIAP) y el Consejo Nacional de Ciencia y Tecnología.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
          
            <Grid item xs={12} md={12}>
              <Zoom direction="left">
                <Card
                  sx={{
                    mb: 2,
                    py: 1,
                    margin: { xs: "none", md: "30px" },
                    border: "1px solid #87CEEB",
                    marginTop: { xs: "none", md: "-15px" },
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)", 
                  }}
                >
                  <CardContent>
                    <Grid container>
                      <Grid item container xs={12}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                            variant="h5"
                            component="div"
                          >
                            <PsychologyIcon fontSize="large" sx={{color:'#3ca9f2'}} />
                            Desafíos y Resiliencia
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "0.9rem",
                                sm: "1rem",
                                md: "1.1rem",
                                lg: "1rem",
                              },
                            }}
                            variant="body1"
                            color="text.secondary"
                            textAlign="justify"
                          >
                            Los pueblos indígenas de la Amazonía peruana están
                            enfrentando cambios que afectan sus valores
                            tradicionales y erosionan su conocimiento acumulado
                            a lo largo de los siglos. Las mujeres indígenas son
                            fundamentales para preservar la identidad y los
                            valores de sus comunidades.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Zoom>
            </Grid>
            <Grid item xs={12} md={12}>
              <Zoom direction={isMobile ? "right" : "left"}>
                <Card
                  sx={{
                    mb: 2,
                    py: 1,
                    height: "100%",
                    margin: { xs: "none", md: "30px" },
                    marginTop: { xs: "none", md: "-10px" },
                    border: "1px solid #f2c1b3",
                    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)", 
                  }}
                >
                  <CardContent>
                    <Grid container>
                      <Grid item container xs={12}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                            variant="h5"
                            component="div"
                          >
                            <Diversity3Icon fontSize="large" sx={{color:'#e4a394'}}/>
                            Conexión Cultural y Empoderamiento
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "0.9rem",
                                sm: "1rem",
                                md: "1.1rem",
                                lg: "1rem",
                              },
                            }}
                            variant="body1"
                            color="text.secondary"
                            textAlign="justify"
                          >
                            Algunas prácticas tradicionales pueden mantener la
                            conexión entre la naturaleza y la cultura, y también
                            tienen potencial económico. Raíces del bosque es una
                            herramienta que ayuda a las mujeres indígenas a
                            destacar su trabajo importante, promover su arte y
                            fomentar negocios locales en sus propias
                            comunidades.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Zoom>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          lg={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
           <Zoom direction="right">
            <Card
              sx={{
                mb: -5,
                py: 1,
                height: "100%",
                border: "1px solid #D3FFCE",
                margin: { xs: "none", md: "30px" },
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)", 
              }}
            >
              <CardContent>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                    variant="h5"
                    component="div"
                    pb={1}
                  >
                    <CheckCircleOutlineIcon fontSize="large" color='success' />
                    OBJETIVO
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "0.9rem",
                        sm: "1rem",
                        md: "1.1rem",
                        lg: "1rem",
                      },
                    }}
                    variant="body1"
                    color="text.secondary"
                    textAlign="justify"
                  >
                    El objetivo central de este proyecto es establecer un
                    profundo aprecio y fomentar la difusión de los saberes
                    ancestrales, las tradiciones arraigadas y los valores
                    fundamentales presentes en las culturas de los pueblos
                    indígenas amazónicos, buscando preservar y difundir su rica
                    herencia cultural.
                  </Typography>
                </Grid>
                <Grid item>
                  <Carousel
                    indicators={false}
                    showIndicators={false}
                    infiniteLoop
                    autoPlay
                    interval={3000}
                    swipeable={false}
                    showStatus={false}
                    showThumbs={false}
                  >
                    {carouselItems.map((item, index) => (
                      <MKBox
                        component="img"
                        key={index}
                        src={item.src}
                        alt={item.alt}
                        sx={{
                          borderRadius: "20px",
                        }}
                      />
                    ))}
                  </Carousel>
                </Grid>
              </CardContent>
            </Card>
          </Zoom>
        </Grid>
      </Grid>
    </Container>
  );
};
