import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Material Kit 2 React components
import { Container } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import RoomIcon from "@mui/icons-material/Room";
import GoogleMapContainer from "presentation/components/GoogleMapsContainer";
import fetchDataCommunity from "infraestructura/api/fetchDataCommunity";

function Location() {
  const { id } = useParams();
  const { apiData, loading } = fetchDataCommunity.FetchDataCommById(id);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "10vh",
          margin: "30px 0 30px 0",
        }}
      >
        <CircularProgress color="success" size={60} />
      </div>
    );
  }

  return (
    <Container>
      <Grid container spacing={4} pb={4} pt={8}>
        <MKBox
          display="flex"
          alignItems="center"
          mb={4}
          justifyContent="center"
        >
          <RoomIcon
            fontSize="large"
            sx={{ marginRight: "6px", marginLeft: "27px" }}
          />
          <MKTypography
            variant="h3"
            fontWeight="light"
            component="div"
            opacity={0.8}
            align="center"
          >
            Ubicación del pueblo {apiData.nombre}:
          </MKTypography>
        </MKBox>

        <Grid item xs={12}>
          <GoogleMapContainer lat={apiData.latitud} lng={apiData.longitud} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Location;
