import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import CircularProgress from '@mui/material/CircularProgress';
import fetchDataVenture from "infraestructura/api/fetchDataVenture";
import defaultLogo from "assets/images/logo-raices.png";

function VenturesList() {
  const { apiData, loading } = fetchDataVenture.FetchDataAllVentures();

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
        <CircularProgress color="success" size={60} />
      </div>
    );
  }

  return (
    <MKBox component="section" py={2} mb={4}>
      <Container>
        <MKTypography variant="h2" fontWeight="bold" my={6} align="center">
          Explora nuestros emprendimientos
        </MKTypography>
        <Grid container spacing={3}>
          {apiData.map((venture) => (
            <Grid key={venture.id_emp} item xs={12} sm={6} lg={4}>
              <RotatingCard>
                <RotatingCardFront image={
                  venture.logo
                    ? venture.logo
                    : defaultLogo} bgSize="auto 80%" />

                <RotatingCardBack
                  image={venture.logo
                    ? venture.logo
                    : defaultLogo}
                  title={venture.nombre_emp}
                  action={{
                    type: "internal",
                    route: `/ventures/${venture.id_emp}`,
                    label: "Ver detalle",
                  }}
                />
              </RotatingCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default VenturesList;
