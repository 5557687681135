import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  TextField,
  CircularProgress,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import "../../SignIn/TuArchivoDeEstilos.css";
import Zoom from '@mui/material/Zoom';
import DeleteIcon from "@mui/icons-material/Delete";
import FaceIcon from "@mui/icons-material/Face";
import axios from "axios";
import ImageIcon from "@mui/icons-material/Image";
const token = localStorage.getItem("token_raices");

function ImgVentures({
  open,
  ventureId,
  onClose,
  onDeleteImage,
  onAddImage,
  forceReloadData, // Función para forzar la recarga de datos
}) {
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [showSaveSelectionDialog, setShowSaveSelectionDialog] = useState(false);
  const [logoList, setLogoList] = useState([]);
  const [hasImage, setHasImage] = useState(false);
  const [, setLoading] = useState(true);
  const [imageList, setImageList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [deletemessage, setDeleteMessage] = useState("");
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showdeleteDialog, setdeleteDialog] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [deletingImageId, setDeletingImageId] = useState(null);
  const [deleteWarningMessage, setDeleteWarningMessage] = useState("");
  const [isDeletingLogo, setIsDeletingLogo] = useState(false);

  const handleFileChange = (file) => {
    setSelectedFile(file);

    // Verificar la extensión del archivo
    const allowedExtensions = [".png", ".jpg", ".jpeg"];
    const fileExtension = file
      ? file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)
      : "";

    if (
      fileExtension &&
      !allowedExtensions.includes("." + fileExtension.toLowerCase())
    ) {
      setFileErrorMessage(
        "Solo se admiten archivos con extensiones .png, .jpg y .jpeg."
      );
      setIsSaveButtonDisabled(true); // Deshabilitar el botón
    } else {
      setFileErrorMessage("");
      setIsSaveButtonDisabled(false); // Habilitar el botón
    }
  };

  const handleImagesLoaded = () => {
    setLoading(false);
  };
  // Nueva función para abrir el diálogo de selección
  const handleOpenSaveSelectionDialog = () => {
    setShowSaveSelectionDialog(true);
  };

  // Nueva función para manejar la acción de guardar como LOGO
  const handleSaveAsLogo = async () => {
    setShowSaveSelectionDialog(false);
    await handleAddImage(true); // Pasa true para indicar que se debe guardar como LOGO
  };

  // Nueva función para manejar la acción de guardar como IMAGEN
  const handleSaveAsImage = async () => {
    setShowSaveSelectionDialog(false);
    await handleAddImage(false); // Pasa false para indicar que se debe guardar como IMAGEN
  };
  const handleAddImage = async (isLogo) => {
    try {
      if (selectedFile) {
        setUploading(true);
        setUploadMessage("Cargando...");

        const formData = new FormData();
        formData.append(isLogo ? "url_logo" : "url_imagen", selectedFile);

        await axios.put(
          `${process.env.REACT_APP_API_URL}/ventures/edit-venture/${
            isLogo ? "logo" : "image"
          }/${ventureId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        onAddImage();
        forceReloadData(); // Forzar la recarga de datos
        setSelectedFile(null);
        setUploadMessage("Agregado correctamente");
        setShowUploadDialog(true);

        setTimeout(() => {
          setShowUploadDialog(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
      setUploadMessage("Error al cargar la imagen");
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteImage = (imageId, isLogo) => {
    setDeletingImageId(imageId);
    setDeleteWarningMessage(
      `¿Seguro que deseas eliminar: ${isLogo ? "Logo" : "Imagen"}?`
    );
    setIsDeletingLogo(isLogo); // Asegúrate de configurar correctamente isDeletingLogo
    setShowDeleteWarning(true);
  };

  const handleConfirmDelete = async () => {
    setShowDeleteWarning(false);
    try {
      const deleteEndpoint = isDeletingLogo
        ? `${process.env.REACT_APP_API_URL}/ventures/deleteLogo/${ventureId}`
        : `${process.env.REACT_APP_API_URL}/ventures/deleteImg/${deletingImageId}`;

      await axios.delete(deleteEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const updatedImages = imageList.filter(
        (image) => image.id !== deletingImageId
      );

      setImageList(updatedImages);
      setDeleteMessage("Eliminado correctamente");
      setdeleteDialog(true);
      setTimeout(() => {
        setdeleteDialog(false);
      }, 3000);
      onDeleteImage(deletingImageId); // Llama a la función para eliminar la imagen de la lista
      forceReloadData(); // Forzar la recarga de datos
    } catch (error) {
      console.error("Error deleting image:", error);
    } finally {
      setDeletingImageId(null);
    }
  };

  useEffect(() => {
    const loadImages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/ventures/${ventureId}`
        );

        const imagesData = response.data;

        const logos = imagesData.logo
          ? [{ id: ventureId, url: imagesData.logo }]
          : [];
        const images = imagesData.imagen
          ? [{ id: ventureId, url: imagesData.imagen }]
          : [];

        setLogoList(logos);
        setImageList(images);

        setHasImage(logos.length > 0 && images.length > 0);
        handleImagesLoaded();
      } catch (error) {
        setLogoList([]); // Inicializa el arreglo de logos vacío
        setImageList([]); // Inicializa el arreglo de imágenes vacío
        setHasImage(false);
        handleImagesLoaded();
        console.error("Error loading images:", error);
      }
    };

    if (open) {
      loadImages();
    }
  }, [open, ventureId, forceReloadData]);

  return (
    <Dialog   TransitionComponent={Zoom} // Usa el componente Slide para la animación

    open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          zIndex: "1000",
          backgroundColor: "rgba(237, 237, 237, 0.8)", // Color con transparencia
          borderRadius: "30px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.4)",
          margin: "10px",
          display: "flex",
          backdropFilter: "blur(8px) saturate(200%)",
          justifyContent: "space-between", // Esto alinea los elementos a la derecha
          alignItems: "center", // Esto centra verticalmente los elementos
        }}
      >
        Imágenes
        <Button variant="outlined" onClick={onClose} color="error">
          Cerrar
        </Button>
      </DialogTitle>
      <DialogContent sx={{ mt: "-90px" }}>
        <Grid sx={{ mt: "70px" }} container spacing={2}>
          {/* Logos */}
          {logoList.length !== 0 ? (
            logoList.map((logoObj, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Typography
                  fontWeight="bold"
                  sx={{
                    backgroundColor: "#549e9c",
                    borderRadius: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center", // Centra horizontalmente el contenido
                  }}
                >
                  <FaceIcon /> LOGO:
                </Typography>

                <div style={{ position: "relative" }}>
                  <img
                    src={logoObj.url}
                    alt={`Logo ${index}`}
                    style={{
                      marginTop: "8px",
                      borderRadius: "10px",
                      maxWidth: "100%",
                      width: "100%",
                      height: "auto",
                      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.7)",
                    }}
                  />
                  <div style={{ position: "absolute", bottom: 10, right: 5 }}>
                    <Tooltip title="BORRAR" placement="top" arrow>
                      <IconButton
                        style={{
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.9)",
                          width: "50px",
                          height: "50px",
                        }}
                        color="error"
                        onClick={() => handleDeleteImage(logoObj.id, true)} // Para logotipos
                      >
                        <DeleteIcon
                          style={{
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <Grid textAlign="center" Item xs={12} md={6}>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#9bcbbf",
                  borderRadius: "50px",
                  marginTop: "17px",
                }}
              >
                <FaceIcon />
                LOGO: NO DISPONIBLE
              </p>
            </Grid>
          )}

          {/* Imágenes */}
          {imageList.length !== 0 ? (
            imageList.map((imageObj, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Typography
                  fontWeight="bold"
                  sx={{
                    backgroundColor: "#549e9c",
                    borderRadius: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center", // Centra horizontalmente el contenido
                  }}
                >
                  <ImageIcon />
                  BANNER:
                </Typography>
                <div style={{ position: "relative" }}>
                  <img
                    src={imageObj.url}
                    alt={`Imagen ${index}`}
                    style={{
                      marginTop: "8px",
                      borderRadius: "10px",
                      maxWidth: "100%",
                      width: "100%",
                      height: "auto",
                      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.7)",
                    }}
                  />
                  <div style={{ position: "absolute", bottom: 10, right: 5 }}>
                    <Tooltip title="BORRAR" placement="top" arrow>
                      <IconButton
                        style={{
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.9)",
                          width: "50px",
                          height: "50px",
                        }}
                        color="error"
                        onClick={() => handleDeleteImage(imageObj.id, false)} // Para imágenes
                      >
                        <DeleteIcon
                          style={{
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <Grid textAlign="center" Item xs={12} md={6}>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#9bcbbf",
                  borderRadius: "50px",
                  marginTop: "17px",
                }}
              >
                <ImageIcon />
                BANNER: NO DISPONIBLE
              </p>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      {!hasImage && (
        <DialogActions
          sx={{
            bgcolor: "#d9d9db",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.7)",
          }}
        >
          <>
            <label style={{ width: "100%" }} htmlFor="fileInput">
              <Button
                variant="contained"
                component="span"
                sx={{ width: "100%" }}
              >
                1. Seleccionar Archivo
              </Button>
            </label>
            <input
              key={selectedFile ? "fileInputWithFile" : "fileInputWithoutFile"}
              accept="image/*"
              style={{ display: "none" }}
              id="fileInput"
              type="file"
              onChange={(e) => handleFileChange(e.target.files[0])}
            />
          </>
        </DialogActions>
      )}

      {selectedFile && (
        <Box className={selectedFile ? "slide-up-entering":'' }
         sx={{ bgcolor: "#d9d9db" }} padding={1}>
          <TextField
            fullWidth
            label="Archivo Seleccionado"
            value={selectedFile ? selectedFile.name : ""}
            InputProps={{
              readOnly: true,
            }}
            sx={{ mt: 2 }}
            error={fileErrorMessage !== ""}
            helperText={fileErrorMessage}
          />

          <Button
            variant="outlined"
            onClick={() => setSelectedFile(null)}
            sx={{ width: "100%", mt: 2, mb: "10px" }}
          >
            Limpiar Selección
          </Button>
        </Box>
      )}
      {selectedFile && (
        <DialogActions sx={{ bgcolor: "#d9d9db" }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#549e9c", width: "100%" }}
            disabled={isSaveButtonDisabled} // Establecer el botón como deshabilitado según la condición
            onClick={handleOpenSaveSelectionDialog} // Abre el diálogo de selección
          >
            {uploading ? <CircularProgress size={24} /> : "2. Guardar Imagen"}
          </Button>
        </DialogActions>
      )}

      {/* Diálogo de selección */}
      <Dialog
        open={showSaveSelectionDialog}
        onClose={() => setShowSaveSelectionDialog(false)}
      >
        <DialogTitle>GUARDAR COMO: </DialogTitle>
        <DialogContent>
          <div style={{ color: "red" }}>
            NOTA:{" "}
            <spam style={{ color: "black", textAlign: "justify" }}>
              Cada nuevo archivo reemplazará la anterior según el tipo que
              elija.
            </spam>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={handleSaveAsLogo} // Guardar como LOGO
              sx={{ margin: 1, bgcolor: "#549e9c", color: "black" }}
            >
              <FaceIcon />
              LOGO
            </Button>
            <Button
              variant="contained"
              onClick={handleSaveAsImage} // Guardar como IMAGEN
              sx={{ margin: 1, bgcolor: "#549e9c", color: "black" }}
            >
              <ImageIcon />
              BANNER
            </Button>
          </div>{" "}
        </DialogContent>
      </Dialog>
      <Dialog
        open={showUploadDialog}
        onClose={() => setShowUploadDialog(false)}
      >
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            {uploading ? (
              <CircularProgress />
            ) : (
              <p style={{ margin: 0 }}>{uploadMessage}</p>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showdeleteDialog} onClose={() => setdeleteDialog(false)}>
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            {uploading ? (
              <CircularProgress />
            ) : (
              <p style={{ margin: 0 }}>{deletemessage}</p>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showDeleteWarning}
        onClose={() => setShowDeleteWarning(false)}
      >
        <DialogTitle>¿Estás seguro?</DialogTitle>
        <DialogContent>
          <p>{deleteWarningMessage}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteWarning(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Sí, Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

export default ImgVentures;
