import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Button,
  TextField,
  Snackbar,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import ProductDetail from "./sections/productDetails";
import RelatedProducts from "./sections/relatedProducts";
import MKBox from "components/MKBox";
import CarouselSection from "presentation/components/HomePage/sections/Carousel";
import CircularProgress from "@mui/material/CircularProgress";
import noImage from "assets/images/no-image.png";
import fetchDataProducts from "infraestructura/api/fetchDataProducts";
import wasaplogo from "assets/images/wasaplogo.png";
import "./Wasap.css";
import { Zoom } from "react-awesome-reveal";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import useContactInfo from "infraestructura/api/useContactInfo";

const useProductInfo = (id) => {
  const { apiData, loading } = fetchDataProducts.FetchDataProdById(id);

  const mainImage = apiData.imagenes?.[0] || noImage;
  let smallImages = apiData.imagenes;
  if (!Array.isArray(smallImages) || smallImages.length <= 1) {
    smallImages = [];
  }

  const product = {
    name: apiData.nombre_prod,
    price: apiData.precio,
    artisan: apiData.nombre_artesano,
    desc: apiData.desc_producto,
    desc_medidas: apiData.desc_medidas,
    emprendimiento: apiData.emprendimiento,
    categoria: apiData.categoria,
    id_emp: apiData.id_emp,
  };

  const id_emp = apiData.id_emp;
  const id_categ = apiData.id_categoria;

  return { product, mainImage, smallImages, id_emp, id_categ, loading };
};

const Product = () => {
  const { id } = useParams();
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const { product, mainImage, smallImages, id_emp, id_categ, loading } =
    useProductInfo(id);
  const { contactInfo, Cloading, error, editContactInfo } = useContactInfo();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const isUserAuthenticated = !!localStorage.getItem("token_raices");
  const [whatsappLink, setWhatsappLink] = useState("");

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  const handlePhoneNumberChange = (event) => {
    let inputValue = event.target.value;

    // Aplica la validación de números y longitud máxima
    inputValue = inputValue.replace(/[^0-9]/g, ""); // Elimina caracteres no numéricos
    inputValue = inputValue.slice(0, 9); // Limita la longitud a 9 dígitos

    setNewPhoneNumber(inputValue);
  };

  const handleUpdatePhoneNumber = () => {
    editContactInfo(newPhoneNumber);
    setDialogOpen(false);
    setConfirmationMessage("Número de teléfono actualizado con éxito.");
  };

  const handleImageClick = (image) => {
    console.log("Cambiando imagen principal a:", image);
  };

  useEffect(() => {
    setWhatsappLink(
      `https://api.whatsapp.com/send?phone=+51${contactInfo.telefono}&text=Me%20interesa%20este%20producto%20😊:%20${product.name}%20https://raicesdelbosque.iiap.gob.pe/products/${id}`
    );
  }, [contactInfo.telefono, product.name, id]);

  return (
    <MKBox>
      <CarouselSection
        textHeader="Detalles del Producto"
        heightHeader="23rem"
      />

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container maxWidth="lg" sx={{ py: 2 }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress color="success" size={60} />
            </div>
          ) : (
            <>
              <ProductDetail
                mainImage={mainImage}
                smallImages={smallImages}
                onImageClick={handleImageClick}
                {...product}
              />

              <Divider sx={{ my: 2, borderWidth: 1 }} />

              {id_emp && id_categ ? (
                <RelatedProducts id_emp={id_emp} id_categ={id_categ} />
              ) : (
                <div>No se encontraron datos relacionados</div>
              )}
            </>
          )}
        </Container>
      </Card>
      <Box
        sx={{
          position: "fixed",
          bottom: { xs: "15px", md: "35px" },
          right: { xs: "15px", md: "35px" },
        }}
      >
        <Zoom direction="down">
          {/* Primer IconButton (WhatsApp) */}
          <Tooltip title="Escríbenos" placement="top">
            <IconButton
              sx={{
                bgcolor: "#ebeff1",
                zIndex: "9998",
                border: "2px solid #128c7e",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                transition: "transform 0.2s",
                mb: "-4px",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                <img
                  src={wasaplogo}
                  alt="WhatsApp Logo"
                  className="custom-image"
                />
              </a>
            </IconButton>
          </Tooltip>

          {isUserAuthenticated && (
            <IconButton
              onClick={handleOpenDialog}
              sx={{
                bgcolor: "White",
                zIndex: "9999",
                border: "2px solid #128c7e",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <SettingsIcon />
            </IconButton>
          )}
        </Zoom>
      </Box>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
        <IconButton
          sx={{ position: "absolute", top: 0, right: 0 }}
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography variant="h6">CAMBIAR NÚMERO</Typography>

          <TextField
            label="Número de 9 dígitos"
            variant="outlined"
            fullWidth
            value={newPhoneNumber}
            onChange={handlePhoneNumberChange}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Acepta solo caracteres numéricos
            sx={{ mt: 2 }}
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              color="success"
              onClick={handleUpdatePhoneNumber}
              sx={{ mt: 2, color: "Black" }}
              disabled={newPhoneNumber.length !== 9}
            >
              Actualizar Número
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {confirmationMessage && (
        <Snackbar
          open={Boolean(confirmationMessage)}
          autoHideDuration={6000}
          onClose={() => setConfirmationMessage("")}
          message={confirmationMessage}
        />
      )}
    </MKBox>
  );
};

export default Product;
