import React, { useState, useEffect } from "react";
import { Outlet, Navigate, Link } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import { useSwipeable } from "react-swipeable";
import { Box} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MainListItems } from "presentation/components/Administrador/dashboard/listItems";
import CenteredFooter from "examples/Footers/CenteredFooter";
import "./bg.css";
import ff from "assets/images/footer.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import useAuth from "utils/hooks/useAuth";
import axios from "axios";
import LoadingIndicator from "components/Loading";
import logo from "assets/images/logo-raices.png";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  borderRadius:'15px',
  backgroundColor: "rgba(255, 255, 255, 0.7)", // Fondo transparente
  backdropFilter: "blur(6px)", // Aplicar desenfoque si está abierta
  WebkitBackdropFilter: open ? "blur10px)" : "none", // Para navegadores antiguos
  filter: open ? "saturate(200%)" : "none", // Aplicar saturación cuando esté abierta
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const defaultTheme = createTheme();

export default function Dashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(!isMobile);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userName, setUserName] = useState(
    localStorage.getItem("user_name") || ""
  );
  const { auth, loading } = useAuth();
  const token = localStorage.getItem("token_raices");
  const apiUrl = `${process.env.REACT_APP_API_URL}/users`;

  useEffect(() => {
    fetchUserData();
  }, );

  const handleLogout = () => {
    localStorage.removeItem("token_raices");
    window.location.href = "/login";
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const user = response.data.find((u) => u.id_usuario === auth.id_user);
      const newUserName = user.username;
      setUserName(newUserName);
      localStorage.setItem("user_name", newUserName);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (open) {
        toggleDrawer();
      }
    },
    onSwipedRight: () => {
      if (!open) {
        toggleDrawer();
      }
    },
    preventDefaultTouchmoveEvent: true, // Esto evita el comportamiento de desplazamiento por defecto
  });

  if (loading) {
    return (<LoadingIndicator/>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px",
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{
                color: "black",
                position: "relative",
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                fontSize: {
                  xs: "0.9rem",
                  sm: "1.1rem",
                  md: "1.3rem",
                  lg: "1.4rem",
                },
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{ marginRight: "5px", height: "30px" }}
              />
              ADMINISTRACIÓN
            </Typography>
            <Typography
              sx={{
                color: "black",
                backgroundColor: "#eeeeee",
                borderRadius: "10px",
                fontSize: {
                  xs: "1rem",
                  sm: "1.1rem",
                  md: "1.3rem",
                  lg: "1.4rem",
                },
              }}
            >
              {userName || "?"}
            </Typography>

            <IconButton color="inherit" onClick={handleMenuOpen}>
              <Badge badgeContent={0} color="success">
                <AccountCircleIcon
                  fontSize="inherit"
                  sx={{ color: "#386b69" }}
                />
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Volver a Inicio
                </Link>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Link
                  to="#"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Cerrar Sesión
                </Link>
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open} {...swipeHandlers}>
          <Toolbar
            sx={{
              backgroundColor:'#549e9c',
              backgroundSize: "cover", // Ajusta la imagen al contenedor
              backgroundPosition: "center", // Ajusta la posición de la imagen
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
              transform: "rotateX(180deg)", // Muestra la imagen boca abajo
            }}
          >
            <IconButton size="large" onClick={toggleDrawer}>
              <ChevronLeftIcon 
              fontSize="large"
                className="vibrate-icon" // Aplica la clase vibrate-icon aquí
                sx={{ color: "white", fontSize: "35px" }}
              />
            </IconButton>
          </Toolbar>
          <Divider />
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              justifyContent: "space-between",
              height: "100%",
              position: "relative",
            }}
            component="nav"
          >
            <div className="background-image" /> <MainListItems />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: "#eeeeee",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <Container maxWidth="lg" sx={{ mt: 2, }}>
            {auth?.id_user ? <Outlet /> : <Navigate to="/login" />}
          </Container>

          <CenteredFooter light />
        </Box>
      </Box>
    </ThemeProvider>
  );
}
