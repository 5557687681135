import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import Diversity1RoundedIcon from "@mui/icons-material/Diversity1Rounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";

const StyledListItemButton = ({ to, primary, icon, onClick, isSelected }) => {
  const buttonStyles = {
    color: "white",
    marginLeft: "5px",
    marginRight: "5px",
    borderRadius: "8px",
    margin:'15px',
    backgroundColor: isSelected ? "#549e9c" : "initial",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.9)",
    backdropFilter:  "blur(3px) brightness(1.5)"
  };

  return (
    <ListItemButton component={Link} to={to} onClick={onClick} style={buttonStyles} >
      <ListItemIcon>{React.cloneElement(icon, { style: { color: "white",marginLeft:'-10px' } })}</ListItemIcon>
      <ListItemText primary={primary} style={{ color: "white" }} />
    </ListItemButton>
  );
};

export const MainListItems = () => {
  const location = useLocation();
  const [selectedButton, setSelectedButton] = useState(null);

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname === "/admin") {
      setSelectedButton("dashboard");
    } else {
      const buttons = ["categories", "towns", "ventures", "products", "investigation", "slider", "information"];
      const activeButton = buttons.find(button => pathname.includes(button));
      setSelectedButton(activeButton || null);
    }
  }, [location.pathname]);

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  return (
    <React.Fragment>
      <StyledListItemButton
        to="/admin"
        primary="Dashboard"
        icon={<DashboardIcon />}
        isSelected={selectedButton === "dashboard"}
        onClick={() => handleButtonClick("dashboard")}
      />
      <StyledListItemButton
        to="/admin/categories"
        primary="Categorías"
        icon={<LocalOfferRoundedIcon />}
        isSelected={selectedButton === "categories"}
        onClick={() => handleButtonClick("categories")}
      />
      <StyledListItemButton
        to="/admin/towns"
        primary="Pueblos"
        icon={<Diversity1RoundedIcon />}
        isSelected={selectedButton === "towns"}
        onClick={() => handleButtonClick("towns")}
      />
      <StyledListItemButton
        to="/admin/ventures"
        primary="Emprendimientos"
        icon={<StorefrontRoundedIcon />}
        isSelected={selectedButton === "ventures"}
        onClick={() => handleButtonClick("ventures")}
      />
      <StyledListItemButton
        to="/admin/products"
        primary="Productos"
        icon={<ShoppingBagRoundedIcon />}
        isSelected={selectedButton === "products"}
        onClick={() => handleButtonClick("products")}
      />
      <StyledListItemButton
        to="/admin/investigation"
        primary="Investigaciones"
        icon={<DescriptionRoundedIcon />}
        isSelected={selectedButton === "investigation"}
        onClick={() => handleButtonClick("investigation")}
      />
     {/*  <StyledListItemButton
        to="/admin/information"
        primary="Información"
        icon={<InfoIcon />}
        isSelected={selectedButton === "information"}
        onClick={() => handleButtonClick("information")}
      /> */}
    </React.Fragment>
  );
};
