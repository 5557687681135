import { Container, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import MKTypography from "components/MKTypography";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import bgHeaderDefault from "assets/images/header.jpeg";
import { Zoom } from "react-awesome-reveal";

function CarouselSection({
    position,
    children,
    textHeader,
    bgHeader = bgHeaderDefault,
    heightHeader = "90vh",
}) {
    const carouselItems = [
        /*   {
        src: "/images/img2.jpeg",
        alt: "Descripción de la imagen 2",
    }, */
        {
            src: bgHeader,
            alt: textHeader,
        },
        /*  {
        src: "/images/img4.jpeg",
        alt: "Descripción de la imagen 4",
    }, */
    ];
    const style = {
        position: position || 'relative', // Valor predeterminado 'relative' si no se proporciona 'position'
      };

    return (
        <>
            <Box style={style}>
            {children}
                <Carousel
                    showArrows={false}
                    indicators={false}
                    showIndicators={false}
                    infiniteLoop
                    autoPlay
                    interval={3000}
                    swipeable={false}
                    showStatus={false}
                    showThumbs={false}
                    style={{ height: "auto", width: "140%" }}
                >
                    {carouselItems.map((item, index) => (
                        <Box
                            component="img"
                            key={index}
                            src={item.src}
                            alt={item.alt}
                            sx={{
                                filter: "brightness(40%)",
                                height: heightHeader,
                                objectFit: "cover",
                            }}
                        />
                    ))}
                </Carousel>

                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    color="white"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    sx={{
                        zIndex: 1,
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <Container maxWidth="lg">
                        <Grid container justifyContent="center" > 
                            <Grid item xs={12}>
                            <Zoom triggerOnce direction="down">
                                <MKTypography
                                    variant="h1"
                                    component="h1"
                                    align="center"
                                    color="light"
                                    textGradient
                                    sx={{
                                        fontSize: {
                                            xs: "2.7rem",
                                            sm: "3rem",
                                            md: "3.5rem",
                                            lg: "4rem",
                                            filter: "drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.9))"
                                        },
                                    }}
                                >
                                    {textHeader}
                                </MKTypography>
                                </Zoom>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </>
    );
}

export default CarouselSection;
