import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  CircularProgress,
  Tooltip,
  Box,
} from "@mui/material";
import Zoom from '@mui/material/Zoom';
import DeleteIcon from "@mui/icons-material/Delete";
import logo from "assets/images/logo-raices.png";
import axios from "axios";
import "../../SignIn/TuArchivoDeEstilos.css";

const token = localStorage.getItem("token_raices");

function ImgInvestigation({
  open,
  InvId,
  onClose,
  onDeleteImage,
  onAddImage,
  forceReloadData, // Función para forzar la recarga de datos
}) {
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [imageList, setImageList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [deletemessage, setDeleteMessage] = useState("");
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showdeleteDialog, setdeleteDialog] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [deletingImageId, setDeletingImageId] = useState(null);

  const handleFileChange = (file) => {
    setSelectedFile(file);

    // Verificar la extensión del archivo
    const allowedExtensions = [".png", ".jpg", ".jpeg"];
    const fileExtension = file
      ? file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)
      : "";

    if (
      fileExtension &&
      !allowedExtensions.includes("." + fileExtension.toLowerCase())
    ) {
      setFileErrorMessage(
        "Solo se admiten archivos con extensiones .png, .jpg y .jpeg."
      );
      setIsSaveButtonDisabled(true); // Deshabilitar el botón
    } else {
      setFileErrorMessage("");
      setIsSaveButtonDisabled(false); // Habilitar el botón
    }
  };

  const handleImagesLoaded = () => {
    setLoading(false);
  };

  const handleAddImage = async () => {
    try {
      if (selectedFile) {
        setUploading(true);
        setUploadMessage("Cargando...");

        const formData = new FormData();
        formData.append("url_imagen", selectedFile);

        await axios.post(
          `${process.env.REACT_APP_API_URL}/researchs/addImg/${InvId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        onAddImage();
        forceReloadData(); // Forzar la recarga de datos
        setSelectedFile(null);
        setUploadMessage("Agregado correctamente");
        setShowUploadDialog(true);

        setTimeout(() => {
          setShowUploadDialog(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
      setUploadMessage("Error al cargar la imagen");
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteImage = (imageId) => {
    setDeletingImageId(imageId);
    setShowDeleteWarning(true);
  };

  const handleConfirmDelete = async () => {
    setShowDeleteWarning(false);

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/researchs/deleteImg/${deletingImageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Filtra la imagen que se eliminó según su ID
      const updatedImages = imageList.filter(
        (image) => image.id !== deletingImageId
      );

      // Actualiza el estado de imageList para reflejar la eliminación
      setImageList(updatedImages);
      setDeleteMessage("Eliminado correctamente");
      setdeleteDialog(true);
      setTimeout(() => {
        setdeleteDialog(false);
      }, 3000);
      onDeleteImage(deletingImageId); // Llama a la función para eliminar la imagen de la lista
      forceReloadData(); // Forzar la recarga de datos
    } catch (error) {
      console.error("Error deleting image:", error);
    } finally {
      setDeletingImageId(null);
    }
  };

  useEffect(() => {
    const loadImages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/researchs/imgResearchs/${InvId}`
        );

        const imagesData = response.data; // Array de objetos

        // Extraer las URLs de las imágenes y sus IDs de los objetos y crear un nuevo array
        const images = imagesData.map((imageObj) => {
          if (imageObj.imagenes && imageObj.imagenes.length > 0) {
            return {
              id: imageObj.id_img_inv, // Agregar el ID de la imagen
              url: imageObj.imagenes[0], // Agregar la URL de la imagen
            };
          }
          // Si no hay URLs de imágenes, puedes proporcionar una URL predeterminada
          return {
            id: imageObj.id_img_inv, // Agregar el ID de la imagen sin URL
            url: logo, // O cualquier otra URL predeterminada
          };
        });

        setImageList(images);
        handleImagesLoaded();
      } catch (error) {
        // En caso de error, asignar la URL del logo
        setImageList([logo]);
        handleImagesLoaded();
        console.error("Error loading images:", error);
      }
    };

    if (open) {
      loadImages();
    }
  }, [open, InvId, forceReloadData]);

  return (
    <Dialog TransitionComponent={Zoom}
     open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          zIndex: "1000",
          backgroundColor: "rgba(237, 237, 237, 0.8)", // Color con transparencia
          borderRadius: "30px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.4)",
          margin: "10px",
          display: "flex",
          backdropFilter: "blur(8px) saturate(200%)",
          justifyContent: "space-between", // Esto alinea los elementos a la derecha
          alignItems: "center", // Esto centra verticalmente los elementos
        }}
      >
        Imágenes
        <Button variant="outlined" onClick={onClose} color="error">
          Cerrar
        </Button>
      </DialogTitle>

      <DialogContent sx={{ mt: "-90px" }}>
        <Grid sx={{ mt: "70px" }} container spacing={2}>
          {loading ? (
            Array.from({ length: 4 }).map((_, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Skeleton
                  sx={{
                    marginTop: "8px",
                    borderRadius: "10px",
                    maxWidth: "100%",
                  }}
                  width="100%"
                  variant="rectangular"
                  height={200}
                  animation="wave"
                />
              </Grid>
            ))
          ) : imageList.length !== 0 ? (
            imageList.map((imageObj, index) => (
              <Grid item xs={12} md={6} key={index}>
                <div style={{ position: "relative" }}>
                  <img
                    src={imageObj.url}
                    alt={`Imagen ${index}`}
                    style={{
                      marginTop: "8px",
                      borderRadius: "10px",
                      maxWidth: "100%",
                      width: "100%",
                      height: "auto",
                      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.7)",
                    }}
                  />
                  <div style={{ position: "absolute", bottom: 10, right: 5 }}>
                    <Tooltip title="BORRAR" placement="top" arrow>
                      <IconButton
                        style={{
                          backgroundColor: "#ffffff",
                          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.9)",
                          width: "50px",
                          height: "50px",
                        }}
                        color="error"
                        onClick={() => handleDeleteImage(imageObj.id)} // Utiliza la propiedad 'id' del objeto 'image' como el ID a eliminar
                      >
                        <DeleteIcon
                          style={{
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <p style={{ padding: "10px" }}>No hay imágenes disponibles.</p>
          )}
        </Grid>
        <input
          key={selectedFile ? "fileInputWithFile" : "fileInputWithoutFile"}
          accept="image/*"
          style={{ display: "none" }}
          id="fileInput"
          type="file"
          onChange={(e) => handleFileChange(e.target.files[0])}
        />
      </DialogContent>
      <DialogActions
        sx={{ bgcolor: "#d9d9db", boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.7)" }}
      >
        <label style={{ width: "100%" }} htmlFor="fileInput">
          <Button variant="contained" component="span" sx={{ width: "100%" }}>
            1. Seleccionar Archivo
          </Button>
        </label>
      </DialogActions>

      {selectedFile && (
        <Box className={selectedFile ? "slide-up-entering":'' }
         sx={{ bgcolor: "#d9d9db" }} padding={1}>
          <TextField
            fullWidth
            label="Archivo Seleccionado"
            value={selectedFile ? selectedFile.name : ""}
            InputProps={{
              readOnly: true,
            }}
            sx={{ mt: 2 }}
            error={fileErrorMessage !== ""}
            helperText={fileErrorMessage}
          />
          <Button
            variant="outlined"
            onClick={() => setSelectedFile(null)}
            sx={{ width: "100%", mt: 2 }}
          >
            Limpiar Selección
          </Button>
        </Box>
      )}

      {selectedFile && (
        <DialogActions sx={{ backgroundColor: "#d9d9db" }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#549e9c", width: "100%" }}
            onClick={handleAddImage}
            disabled={isSaveButtonDisabled} // Establecer el botón como deshabilitado según la condición
          >
            {uploading ? (
              <CircularProgress size={24} />
            ) : (
              "2. Agregar nueva Imagen"
            )}
          </Button>
        </DialogActions>
      )}

      <Dialog
        open={showUploadDialog}
        onClose={() => setShowUploadDialog(false)}
      >
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            {uploading ? (
              <CircularProgress />
            ) : (
              <p style={{ margin: 0 }}>{uploadMessage}</p>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showdeleteDialog} onClose={() => setdeleteDialog(false)}>
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            {uploading ? (
              <CircularProgress />
            ) : (
              <p style={{ margin: 0 }}>{deletemessage}</p>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showDeleteWarning}
        onClose={() => setShowDeleteWarning(false)}
      >
        <DialogTitle>¿Estás seguro?</DialogTitle>
        <DialogContent>
          <p>¿Seguro que deseas eliminar esta imagen?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteWarning(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Sí, Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

export default ImgInvestigation;
