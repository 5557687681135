import Card from "@mui/material/Card";
import InfoInv from "./sections/infoInv";
import CarouselSection from "presentation/components/HomePage/sections/Carousel";
import MKBox from "components/MKBox";

const Investigation = () => {
    return (
        <MKBox>
            <CarouselSection
                textHeader="Investigaciones"
                heightHeader="25rem"
            />

            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    backgroundColor: ({
                        palette: { white },
                        functions: { rgba },
                    }) => rgba(white.main, 0.8),
                    backdropFilter: "saturate(200%) blur(30px)",
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <InfoInv />
            </Card>
        </MKBox>
    );
};

export default Investigation;
