import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

function GoogleMapContainer({ lat, lng }) {
  const isValidLatitude = typeof lat === "number" && !isNaN(lat);
  const isValidLongitude = typeof lng === "number" && !isNaN(lng);

  const defaultLat = -3.7673853144298244;
  const defaultLng = -73.27470462961259;

  const mapContainerStyle = {
    width: "100%",
    height: "215px",
    borderRadius:'15px'
  };

  const center = {
    lat: isValidLatitude ? lat : defaultLat,
    lng: isValidLongitude ? lng : defaultLng,
  };

  const mapOptions = {
    zoom: 17,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyC0dwXP9Y3xhbV4jFcI1dsTynm9zCzL0Ik">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        options={mapOptions}
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
}

export default GoogleMapContainer;
