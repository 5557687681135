// @mui material components
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import InfoCommunity from "presentation/components/CommunityPage/sections/InfoCommunity";
import Location from "presentation/components/CommunityPage/sections/Location";
import Posts from "presentation/components/CommunityPage/sections/Posts";

function Community() {
    return (
        <>
            <InfoCommunity />
            <Posts />
            <Divider sx={{ my: 2, borderWidth: 2 }} />
            <Location />
        </>
    );
}

export default Community;
