import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import CollectionsIcon from "@mui/icons-material/Collections";
import ImgInvestigation from "./IMAGE/ImgInvestigation";
import { Fade } from "react-awesome-reveal";

const CenteredCell = ({ children }) => (
  <TableCell
    sx={{
      fontSize: {
        xs: "0.7rem",
        sm: "0.8rem",
        md: "0.9rem",
        lg: "1rem",
      },
      textAlign: "justify",
    }}
  >
    {children}
  </TableCell>
);

const apiUrl = `${process.env.REACT_APP_API_URL}/researchs`;

function Investigation() {
  const theme = useTheme();
  const [reloadData, setReloadData] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedInvId, setSelectedInvId] = useState(null);
  const [investigations, setInvestigations] = useState([]);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  const token = localStorage.getItem("token_raices");
    // Función para forzar la recarga de datos
    const forceReloadData = () => {
      setReloadData(!reloadData);
    };
  useEffect(() => {
    fetchData();
  }, [reloadData]);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl);
      setInvestigations(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditClick = (id, title, description) => {
    setEditId(id);
    setEditTitle(title);
    setEditDescription(description);
    setOpenEditDialog(true);
    setDescriptionError(false); // Restablecer el estado de error a false
    setTitleError(false); // Restablecer el estado de error a false
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setEditId(null);
    setEditTitle("");
    setEditDescription("");
  };

  const handleEditSubmit = async () => {
    try {
      setIsSaving(true);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const dataToUpdate = {
        titulo: editTitle,
        descripcion: editDescription,
      };
      // Verifica si los campos están vacíos
      if (editTitle.trim() === "") {
        setTitleError(true);
      } else {
        setTitleError(false);
      }

      if (editDescription.trim() === "") {
        setDescriptionError(true);
      } else {
        setDescriptionError(false);
      }

      // Si alguno de los campos está vacío, no envíes la solicitud
      if (titleError || descriptionError) {
        return;
      }

      if (editId) {
        await axios.patch(`${apiUrl}/edit-research/${editId}`, dataToUpdate, {
          headers,
        });
      } else {
        await axios.post(`${apiUrl}/create-research`, dataToUpdate, {
          headers,
        });
      }

      handleEditDialogClose();
      fetchData();
    } catch (error) {
      console.error("Error editing data:", error);
    } finally {
      setIsSaving(false); // Detener la animación de carga
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteSubmit = async (id) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${apiUrl}/delete-research/${id}`, { headers });

      handleDeleteDialogClose();
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleAddInvestigation = () => {
    setEditId(null);
    setEditTitle("");
    setEditDescription("");
    setOpenEditDialog(true);
  };

  return (
    <Fade>
    <Container>
      <Typography textAlign="center" variant="h4" gutterBottom>
        INVESTIGACIONES
      </Typography>
      <Paper elevation={10}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{bgcolor:'#f8f9fa'}}>
                <CenteredCell>ID</CenteredCell>
                <CenteredCell>Título</CenteredCell>
                {isMobile ? null : <CenteredCell>Descripción</CenteredCell>}
                <CenteredCell>Imágenes</CenteredCell>
                <CenteredCell>Acciones</CenteredCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <CenteredCell>
                    <Skeleton animation="wave" height={30} />
                  </CenteredCell>
                  <CenteredCell>
                    <Skeleton animation="wave" height={30} />
                  </CenteredCell>
                  <TableCell>
                    <Skeleton animation="wave" height={30} />
                  </TableCell>
                  {isMobile ? null : (
                    <TableCell>
                      <Skeleton animation="wave" height={30} />
                    </TableCell>
                  )}
                   <TableCell>
                    <Skeleton animation="wave" height={30} />
                  </TableCell>
                </TableRow>
              ) : (
                investigations.map((investigation, index) => (
                  <TableRow key={investigation.id_investigacion}>
                    <CenteredCell>{index + 1}</CenteredCell> {/* Cambio aquí */}
                    <CenteredCell>{investigation.titulo}</CenteredCell>
                    {isMobile ? null : (
                      <CenteredCell>
                        {expandedDescriptions[investigation.id_investigacion] ||
                        investigation.descripcion.length <= 300 ? (
                          <>
                            {investigation.descripcion}
                            {investigation.descripcion.length > 300 && (
                              <Button
                                variant="text"
                                size="small"
                                onClick={() =>
                                  setExpandedDescriptions((prevState) => ({
                                    ...prevState,
                                    [investigation.id_investigacion]: false,
                                  }))
                                }
                              >
                                Ver menos
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {investigation.descripcion.substring(0, 300)}...
                            <Button
                              variant="text"
                              size="small"
                              onClick={() =>
                                setExpandedDescriptions((prevState) => ({
                                  ...prevState,
                                  [investigation.id_investigacion]: true,
                                }))
                              }
                            >
                              Ver más
                            </Button>
                          </>
                        )}
                      </CenteredCell>
                    )}
                    <TableCell sx={{ textAlign: "center" }}>
                      <Tooltip title="VER IMAGEN" placement="top" arrow>
                        <IconButton sx={{ backgroundColor: "#ececec" }}>
                          <CollectionsIcon
                            sx={{
                              backgroundColor: "#ececec",
                              fontSize: "30px",
                            }}
                            color="primary"
                            onClick={() => {
                              setSelectedInvId(investigation.id_investigacion);
                              setOpenImageModal(true);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>{" "}
                    <TableCell sx={{ textAlign: "center" }}>
                      <Tooltip title="EDITAR" placement="top" arrow>
                        <IconButton
                          sx={{ margin: "5px", backgroundColor: "#eeeeee" }}
                          color="primary"
                          size="large"
                          onClick={() =>
                            handleEditClick(
                              investigation.id_investigacion,
                              investigation.titulo,
                              investigation.descripcion
                            )
                          }
                        >
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="BORRAR" arrow>
                        <IconButton
                          sx={{ backgroundColor: "#eeeeee" }}
                          size="large"
                          color="error"
                          onClick={() =>
                            handleDeleteClick(investigation.id_investigacion)
                          }
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Grid container justifyContent="center" sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddInvestigation}
        >
          Agregar Investigación
        </Button>
      </Grid>
      <Dialog
        open={openImageModal}
        onClose={() => {
          setOpenImageModal(false);
          setSelectedImages([]);
        }}
        maxWidth="md"
        fullWidth
      >
        <ImgInvestigation
        open={openImageModal}
        forceReloadData={forceReloadData} // Pasa la función como prop
        loading={loading}
        InvId={selectedInvId} // Pasa el ID del producto seleccionado
        onClose={() => {
          setOpenImageModal(false);
          setSelectedImages([]);
        }}
        onDeleteImage={(index) => {
        }}
        onAddImage={() => {
          forceReloadData();
        }}
      />
      </Dialog>
      <Dialog
        open={openEditDialog}
        onClose={handleEditDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editId ? "Editar Investigación" : "Agregar Investigación"}
        </DialogTitle>{" "}
        <DialogContent>
          <TextField
            fullWidth
            label="Título"
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
            sx={{ mb: 2, marginTop: "10px" }}
            error={titleError} // Utiliza el estado de error
            helperText={titleError ? "El título es requerido" : ""} // Muestra el mensaje de error
          />
          <TextField
            fullWidth
            multiline
            rows={15}
            label="Descripción"
            value={editDescription}
            onChange={(e) => setEditDescription(e.target.value)}
            error={descriptionError} // Utiliza el estado de error
            helperText={descriptionError ? "La descripción es requerida" : ""} // Muestra el mensaje de error
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleEditSubmit}
            color="primary"
            disabled={isSaving}
          >
            {isSaving ? <CircularProgress size={24} /> : "Guardar"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          ¿Estás seguro de que quieres borrar esta investigación?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleDeleteSubmit(deleteId)} color="error">
            Borrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
    </Fade>
  );
}

export default Investigation;
