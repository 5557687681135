import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import noImage from "assets/images/no-image.png";
import fetchDataCommunity from "infraestructura/api/fetchDataCommunity";

function InfoCommunity() {
  const { id } = useParams();
  const { apiData, loading } = fetchDataCommunity.FetchDataCommById(id);

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "10vh", marginTop: "60px" }}>
        <CircularProgress color="success" size={60} />
      </div>
    );
  }

  const carouselItems = apiData.imagenes.map((image, index) => ({
    src: image,
    alt: `Imagen ${index + 1}`,
  }));

  return (
    <Container maxWidth="lg" fixed sx={{ py: 2 }}>
      <MKBox sx={{ flexGrow: 1, py: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <MKTypography variant="h2">{apiData.nombre}</MKTypography>
          </Grid>

          <Grid item xs={12} xl={6} pr={2}>
            <MKTypography variant="subtitle1" align="justify">
              {apiData.descripcion}
            </MKTypography>
          </Grid>

          <Grid
            item
            xs={12}
            xl={6}
            sx={{ filter: "drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.5))" }}
          >
            {carouselItems.length > 0 ? (
              <Carousel
                indicators={false}
                showIndicators={false}
                infiniteLoop
                autoPlay
                interval={3000}
                swipeable={false}
                showStatus={false}
                showThumbs={false}
              >
                {carouselItems.map((item, index) => (
                  <MKBox
                    component="img"
                    key={index}
                    src={item.src}
                    alt={item.alt}
                    sx={{ borderRadius: "20px",
                    maxHeight:{xs:'200px',md:'400px'},
                }}
                  />
                ))}
              </Carousel>
            ) : (
              <MKBox
                component="img" src={noImage} alt="Sin imágenes" />
            )}
          </Grid>
        </Grid>
      </MKBox>
    </Container>
  );
}

export default InfoCommunity;
