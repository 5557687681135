import React from 'react';
import Box from '@mui/material/Box';
import Imgg from 'assets/images/divider.png';

const BoxDivider = ({ mb, mt }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: mb, mt:mt }}>
      <img src={Imgg} alt="Divider" width={200} />
    </Box>
  );
};

export default BoxDivider;
