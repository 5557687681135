import React from "react";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import logo from "assets/images/logo-raices.png";

function LoadingIndicator() {
  return (
    <div>
      <Stack
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        spacing={20}
      >
        <img src={logo} alt="Logo" style={{ marginRight: "5px", height: "30vh" }} />
        <LinearProgress color="success" sx={{ width: "100%", height: "8px" }} />
      </Stack>
    </div>
  );
}

export default LoadingIndicator;
