import React, { useEffect, useState } from 'react';

const TownID = () => {
  const [maxTownID, setTownCount] = useState(0);
  const [firstTownName, setFirstTownName] = useState('');

  useEffect(() => {
    fetch('https://api.raicesdelbosque.iiap.gob.pe/api/v1/towns')
      .then(response => response.json())
      .then(data => {
        const count = data.length; // Contamos los elementos en lugar de obtener el ID más alto
        setTownCount(count);

        if (data.length > 0) {
          const firstTown = data[0]; // Obtén el primer elemento
          setFirstTownName(firstTown.nombre); // Supongo que el nombre se encuentra en la propiedad 'nombre'
        }
      })
      .catch(error => {
        console.error('Error al obtener el número de pueblos y el nombre del primer pueblo', error);
      });
  }, []);

  return { maxTownID, firstTownName };
};

export default TownID;
