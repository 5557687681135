import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MKBox from "components/MKBox";
import Card from "@mui/material/Card";
import CarouselSection from "../components/HomePage/sections/Carousel";
import Investigation from "../components/HomePage/sections/Investigation";
import { AboutSection } from "../components/HomePage/sections/AboutSection";
import Contact from "presentation/components/HomePage/sections/Contact";
import Product from "presentation/components/HomePage/sections/Product";
import VentureHome from "presentation/components/HomePage/sections/Venture";
import BoxDivider from "components/BoxDivider";
import { Bounce } from "react-awesome-reveal";

const HomePage = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <MKBox>
      <CarouselSection textHeader="¡RAÍCES DEL BOSQUE!" />

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 4 },
          mt: -8,
          mb: 4,

          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(25px)",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
          border: "2px solid #d9d9db",
        }}
      >
        {" "}
        <BoxDivider mt="50px" mb="50px" />
        <AboutSection />
        <BoxDivider />
        <Investigation />
        <BoxDivider />
        <Product />
        <BoxDivider />
        <VentureHome />
        <BoxDivider />
        <Contact />
      </Card>
      <>
        {showButton && (
          <Bounce
            style={{
              position: "fixed",
              bottom: "25px",
              right: "25px", 
              zIndex: 10000,            
            }}
          >
            <Button
            variant="contained
            "
              sx={{
                position: "fixed",
                bottom: "0px",
                color: "Black",
                right: "0px",
                zIndex: 10000,
                border: "1px solid #202124",
                backgroundColor: "#437e7d",
                transition: "transform 1s ease-in-out",
                "&:hover": {
                  backgroundColor: "#80a69b",
                },
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.9)",
                "& .MuiSvgIcon-root": {
                fontSize: "2rem", // Ajusta el tamaño del icono aquí
            },
              }}
              onClick={scrollToTop}
            >
              <KeyboardArrowUpIcon sx={{width:'1.5rem',height:'1.5rem'}}/>
            </Button>
          </Bounce>
        )}
      </>
    </MKBox>
  );
};

export default HomePage;
