import { useState } from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ReactImageMagnify from "react-image-magnify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";



const ProductDetail = ({
  name,
  price,
  desc,
  desc_medidas,
  emprendimiento,
  id_emp,
  categoria,
  artisan,
  mainImage,
  smallImages,
  onImageClick
}) => {
  const [selectedImage, setSelectedImage] = useState(mainImage);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    onImageClick(image);
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex < smallImages.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePreviousImage = () => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  const handleTabType = (event, newValue) => setActiveTab(newValue);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const handleLightboxOpen = () => {
    setLightboxOpen(true);
  };

  const handleLightboxClose = () => {
    setLightboxOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>{name}</title>
        <meta
          property="og:url"
          content={`https://raicesdelbosque.iiap.gob.pe/products/${id_emp}`}
        />
        <meta property="og:type" content="website" />
        <meta name="description" content={desc} />
        <meta property="og:title" content={name} />
        <meta property="og:description" content={desc} />
        <meta property="og:image" content={selectedImage} />
      </Helmet>

      <Container maxWidth="lg" fixed sx={{ py: 5 }}>
        <Link to="/products">
          <Button
            sx={{
              color: "#47618d",
              fontSize: "1rem",
              mb: "30px",
              display: { xs: "block" },
            }}
          >
            <ArrowBackIosIcon /> Atrás
          </Button>
        </Link>
        <MKBox sx={{ flexGrow: 1, py: 4 }}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={12} lg={7}>
              <MKBox
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    md: "center",
                    lg: "left",
                    xl: "left",
                  },
                  alignItems: "center",
                  margin: "0 auto",
                  mb: 2,
                }}
              >
                {!isSmallScreen ? (
                  <>
                    <Grid container>
                      <div
                        onClick={handleLightboxOpen}
                        style={{ zIndex: 100, width: "23rem" }}
                      >
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: "Producto",
                              isFluidWidth: true,
                              src: selectedImage,
                              width: 100,
                            },
                            largeImage: {
                              src: selectedImage,
                              width: 1000,
                              height: 1200,
                              display: "flex",
                              alignItems: "center",
                            },
                            enlargedImageContainerDimensions: {
                              width: "80%",
                              height: "100%",
                            },
                            enlargedImageContainerStyle: {
                              borderRadius: "10px",
                            },
                          }}
                          imageStyle={{
                            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                            borderRadius: "10px",
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <Grid>
                        <div
                          style={{
                            backgroundColor: "#d9d9db",
                            width: "8rem",
                            textAlign: "center",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "6rem",
                          }}
                        >
                          <ImageSearchIcon />
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <img
                    src={selectedImage}
                    alt="Producto"
                    style={{
                      cursor: "pointer",
                      width: "70%",
                      height: "70%",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)",
                      borderRadius: "10px",
                    }}
                    onClick={handleLightboxOpen}
                  />
                )}
              </MKBox>

              <Grid container spacing={1} justifyContent="center">
                {smallImages.map((image, index) => (
                  <Grid item xs={6} md={4} key={index}>
                    <img
                      src={image}
                      alt={`Product ${index + 1}`}
                      onClick={() => handleImageClick(image)}
                      style={{
                        cursor: "pointer",
                        opacity: selectedImage === image ? 1 : 0.5,
                        width: "100%",
                        height: "auto",
                        transition: "opacity 0.3s ease",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={5}>
              <MKTypography
                variant="h4"
                sx={{ textAlign: isSmallScreen ? "center" : "left" }}
              >
                {name}
              </MKTypography>

              <MKTypography
                variant="h4"
                color="success"
                mb={2}
                sx={{ textAlign: isSmallScreen ? "center" : "left" }}
              >
                Precio: s/{price}
              </MKTypography>

              <MKTypography
                variant="subtitle1"
                fontWeight="bold"
                sx={{ display: "inline" }}
              >
                Artesano (a):{" "}
                <MKTypography variant="subtitle1" sx={{ display: "inline" }}>
                  {artisan}
                </MKTypography>
              </MKTypography>

              <Grid
                container
                item
                justifyContent="center"
                xs={12}
                mt={2}
                mx="auto"
              >
                <AppBar position="static">
                  <Tabs value={activeTab} onChange={handleTabType}>
                    <Tab sx={{ fontWeight: "bold" }} label="Descripción" />
                    <Tab sx={{ fontWeight: "bold" }} label="Medidas" />
                  </Tabs>
                </AppBar>
              </Grid>

              {activeTab === 0 && (
                <Grid item xs={12} md={12} mt={2}>
                  <MKTypography variant="subtitle1">{desc}</MKTypography>
                </Grid>
              )}

              {activeTab === 1 && (
                <Grid item xs={12} md={12} mt={2}>
                  <MKTypography variant="subtitle1">
                    {desc_medidas}
                  </MKTypography>
                </Grid>
              )}

              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item xs={12} md={12} lg={6}>
                  <Link to={`/ventures/${id_emp}`}>
                    <MKBadge
                      variant="contained"
                      color="info"
                      badgeContent={emprendimiento}
                      size="lg"
                      container
                    />
                  </Link>
                </Grid>

                <Grid item xs={12} mb={2}>
                  <MKBadge
                    variant="contained"
                    color="info"
                    badgeContent={categoria}
                    size="lg"
                    container
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MKBox>

        <Dialog
          open={lightboxOpen}
          onClose={handleLightboxClose}
          maxWidth="lg"
          fullWidth
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              padding: 0,
              overflow: "hidden",
            },
          }}
        >
          <DialogContent>
            <IconButton
              edge="end"
              color="white"
              onClick={handleLightboxClose}
              aria-label="close"
              sx={{
                position: "absolute",
                top: 0,
                right: 40,
              }}
            >
              <HighlightOffOutlinedIcon />
            </IconButton>

            {smallImages.length > 1 ? (
              <>
                <IconButton
                  edge="start"
                  color="white"
                  onClick={handlePreviousImage}
                  aria-label="previous"
                  sx={{
                    position: "absolute",
                    left: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <ArrowBackIosNewRoundedIcon />
                </IconButton>

                <IconButton
                  edge="end"
                  color="white"
                  onClick={handleNextImage}
                  aria-label="next"
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <ArrowForwardIosRoundedIcon />
                </IconButton>

                <div
                  style={{
                    width: "100%",
                    height: "80vh",
                    backgroundImage: `url(${smallImages[selectedImageIndex]})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    border: "none",
                  }}
                />
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "80vh",
                  backgroundImage: `url(${selectedImage})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  border: "none",
                }}
              />
            )}
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
};

export default ProductDetail;
