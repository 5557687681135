import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import RoomIcon from '@mui/icons-material/Room';
import fetchDataVenture from "infraestructura/api/fetchDataVenture";
import CircularProgress from '@mui/material/CircularProgress';

// Images
import defaultLogo from "assets/images/logo-raices.png";

function Profile() {
  const { id } = useParams();
  const { apiData, loading } = fetchDataVenture.FetchDataVentById(id);

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
        <CircularProgress color="success" size={60} />
      </div>
    );
  }

  return (
    <MKBox component="section">
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -16 }} textAlign="center" pb={{ xs: 6, sm: 12 }} >
            <MKAvatar sx={{ background: "white" }} src={
              apiData.logo
                ? apiData.logo
                : defaultLogo} alt="Logo del emprendimiento" size="xxl" />
          </MKBox>

          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox mb={{ xs: 8, md: 5 }} mt={{ xs: -2, md: -8 }}>
                <MKTypography variant="h1" align="center">
                  {apiData.nombre_emp}
                </MKTypography>

                <MKBox display="flex" alignItems="center" my={2} justifyContent="center">
                  <RoomIcon fontSize="large" color='success' sx={{ marginRight: '6px' }} />
                  <MKTypography
                    component="div"
                    variant="h4"
                    color="success"
                    opacity={0.8}
                    align="center"
                    fontWeight="regular"
                  >
                    {apiData.ubi_tienda}
                  </MKTypography>
                </MKBox>

                <MKTypography variant="body1" fontWeight="light" color="text" align="center">
                  {apiData.descripcion}
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
