import React, { useEffect, useState } from 'react';

const VentureID = () => {
  const [maxVentureID, setVentureCount] = useState(0);
  const [firstVentureName, setFirstVentureName] = useState('');

  useEffect(() => {
    fetch('https://api.raicesdelbosque.iiap.gob.pe/api/v1/ventures')
      .then(response => response.json())
      .then(data => {
        const count = data.length; // Contamos los elementos en lugar de obtener el ID más alto
        setVentureCount(count);

        if (data.length > 0) {
          const firstVenture = data[0]; // Obtén el primer elemento
          setFirstVentureName(firstVenture.nombre_emp); // Supongo que el nombre de la empresa se encuentra en la propiedad 'nombre_emp'
        }
      })
      .catch(error => {
        console.error('Error al obtener el número de empresas y el nombre de la primera empresa', error);
      });
  }, []);

  return { maxVentureID, firstVentureName };
};

export default VentureID;
