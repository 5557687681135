import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import fetchDataVenture from "infraestructura/api/fetchDataVenture";
import noImage from "assets/images/no-image.png";
import defaultLogo from "assets/images/logo-raices.png";

function Ventures() {
  const { id } = useParams();
  const { apiData, loading } = fetchDataVenture.FetchDataVentByCommunity(id);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "10vh",
          margin: "30px 0 30px 0",
        }}
      >
        <CircularProgress color="success" size={60} />
      </div>
    );
  }

  let content = null;

  if (!apiData || !Array.isArray(apiData) || apiData.length === 0) {
    content = (
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <img
            src={noImage}
            alt="No hay emprendimientos"
            style={{
              width: "200px",
              height: "200px",
              alignItems: "center",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <MKTypography variant="h6" color="success" align="center">
            Este pueblo aún no tiene emprendimientos
          </MKTypography>
        </Grid>
      </Grid>
    );
  } else {
    content = (
      <Grid container spacing={3}>
        {apiData.map((venture) => (
          <Grid key={venture.id_emp} item xs={12} sm={6} lg={4}>
            <RotatingCard>
              <RotatingCardFront
                image={venture.logo ? venture.logo : defaultLogo}
                bgSize="auto 80%"
              />

              <RotatingCardBack
                image={venture.logo ? venture.logo : defaultLogo}
                title={venture.nombre_emp}
                action={{
                  type: "internal",
                  route: `/ventures/${venture.id_emp}`,
                  label: "Ver detalle",
                }}
              />
            </RotatingCard>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <MKBox component="section">
      <Container>
        <Grid container item xs={12}>
          <MKTypography variant="h3" fontWeight="bold" mb={4}>
            Explora nuestros emprendimientos
          </MKTypography>
        </Grid>

        {content}
      </Container>
    </MKBox>
  );
}

export default Ventures;
