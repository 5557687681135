import React from 'react';
import Diversity1RoundedIcon from "@mui/icons-material/Diversity1Rounded";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import VentureListPage from "presentation/pages/VentureListPage";
import CommunityPage from "presentation/pages/CommunityPage";
import InvestigationListPage from "presentation/pages/InvestigationListPage";
import ProducstListPage from "presentation/pages/ProductListPage";
import fetchDataCommunity from "infraestructura/api/fetchDataCommunity";

function Header() {
  const { apiData } = fetchDataCommunity.FetchDataAllCommunities();

  const routes = [
      {
          name: "Productos",
          icon: <ShoppingBagRoundedIcon />,
          href: "/products",
          component: <ProducstListPage />,
      },
      {
          name: "Emprendimientos",
          icon: <StorefrontRoundedIcon />,
          href: "/ventures",
          component: <VentureListPage />,
      },
      {
          name: "Pueblos",
          icon: <Diversity1RoundedIcon />,
          collapse: apiData.map((community) => ({
              name: community.nombre,
              href: `/community/${community.id_pueblo}`,
              component: <CommunityPage />,
          })),
      },
      {
          name: "Investigaciones",
          icon: <DescriptionRoundedIcon />,
          href: "/investigations",
          component: <InvestigationListPage />,
      },
  ];

  return (
   
      <DefaultNavbar  routes={routes} sticky />
   
  );
}

export default Header;
