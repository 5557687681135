/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import fetchDataVenture from "infraestructura/api/fetchDataVenture";
import defaultLogo from "assets/images/logo-raices.png";
import { Roll } from "react-awesome-reveal";

function VentureHome() {
  const { apiData, loading } = fetchDataVenture.FetchDataAllVentures();

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Grid container item xs={12} mb={3}>
        <Grid item xs={12}>
          <MKTypography
            variant="h3"
            sx={{
              textAlign: "center",
            }}
            component="div"
            mb={3}
          >
            Nuestros Emprendimientos
          </MKTypography>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            sx={{ textAlign: "center" }}
          >
            {apiData.slice(0, 5).map((venture) => (
              <Grid
                item
                xs={12}
                md={4}
                lg={2}
                key={venture.id_emp}
                sx={{ m: { xs: 2 } }}
              >
                <Tooltip title={venture.nombre_emp}>
                  <MKBox
                    component="a"
                    href={`/ventures/${venture.id_emp}`}
                    target="_blank"
                
                  >
                    <Roll>
                    <MKBox
                      component="img"
                      src={venture.logo ? venture.logo : defaultLogo}
                      sx={{
                        width: { xs:'50%',md: "90%" },
                        "@media (max-width: 425px)": { width: "80%" },
                        transition: "transform 0.2s",
                        "&:hover": {
                          transform: "scale(1.35)",
                        }
                      }}
                      height="auto"
                    />
                    </Roll>
                  </MKBox>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          justifyContent="center"
          mt={4}
          sx={{ textAlign: "center" }}
        >
          <Link to="/ventures" style={{ textDecoration: "none" }}>
            <MKButton variant="outlined" color="success" size="large">
              Ver todos los emprendimientos
            </MKButton>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
}

export default VentureHome;
