import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import FormControl from "@mui/material/FormControl";
import CollectionsIcon from "@mui/icons-material/Collections";
import ImageGallery from "./IMAGE/ImgProducts";
import { Fade } from "react-awesome-reveal";

const apiUrl = `${process.env.REACT_APP_API_URL}/products`;

function Products() {
  const [selectedProductImages, setSelectedProductImages] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [, setSelectedImages] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Valor inicial, puedes cambiarlo según tus necesidades
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // Nuevo estado para el diálogo de eliminación
  const [deleteId, setDeleteId] = useState(null); // Nuevo estado para guardar el ID del producto a eliminar
  const [productsData, setProductsData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [ventures, setVentures] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editData, setEditData] = useState({
    id_producto: "",
    nombre_prod: "",
    nombre_artesano: "",
    precio: "",
    desc_medidas: "",
    desc_producto: "",
    id_categoria: "",
    id_emp: "",
    estado: "",
  });

  // Función para forzar la recarga de datos
  const forceReloadData = () => {
    setReloadData(!reloadData);
  };
  const token = localStorage.getItem("token_raices");

  useEffect(() => {
    fetchData();
    fetchVentures().then((venturesData) => {
      setVentures(venturesData);
    });
    fetchCategories().then((categoriesData) => {
      setCategories(categoriesData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, reloadData]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/categories`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching categories:", error);
      return [];
    }
  };

  const fetchVentures = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ventures`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching ventures:", error);
      return []; // En caso de error, devuelve una lista vacía
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/${currentPage}/${itemsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Modifica los datos del producto para agregar id_categoria e id_emp
      const updatedProductsData = await Promise.all(
        response.data.productsData.map(async (product) => {
          const detailedProductResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/products/${product.id_producto}`
          );
          return {
            ...product,
            id_categoria: detailedProductResponse.data.id_categoria,
            id_emp: detailedProductResponse.data.id_emp,
          };
        })
      );

      setProductsData(updatedProductsData);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditClick = (product) => {
    setEditData(product);
    setOpenEditDialog(true);
  };

  const handleDeleteClick = async (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`${apiUrl}/delete-product/${deleteId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOpenDeleteDialog(false);
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  const CenteredCell = ({ children }) => (
    <TableCell
      sx={{
        fontSize: {
          xs: "0.7rem",
          sm: "0.8rem",
          md: "0.9rem",
          lg: "1rem",
        },
        textAlign: "justify",
      }}
    >
      {children}
    </TableCell>
  );

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setEditData({
      id_producto: "",
      nombre_prod: "",
      nombre_artesano: "",
      precio: "",
      desc_medidas: "",
      desc_producto: "",
      id_categoria: "",
      id_emp: "",
      estado: "",
    });
  };

  const handleEditSubmit = async () => {
    try {
      setIsSaving(true);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const dataToUpdate = {
        nombre_prod: editData.nombre_prod,
        nombre_artesano: editData.nombre_artesano,
        precio: editData.precio,
        desc_medidas: editData.desc_medidas,
        desc_producto: editData.desc_producto,
        id_categoria: editData.id_categoria,
        id_emp: editData.id_emp,
      };

      if (editData.id_producto) {
        // Realiza una solicitud PATCH si hay un ID de producto existente
        await axios.patch(
          `${apiUrl}/edit-product/${editData.id_producto}`,
          dataToUpdate,
          { headers }
        );
      } else {
        // Realiza una solicitud POST si no hay un ID de producto existente
        await axios.post(`${apiUrl}/create-product`, dataToUpdate, { headers });
      }

      setOpenEditDialog(false);
      fetchData();
    } catch (error) {
      console.error("Error editing data:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleAddSubmit = async () => {
    try {
      setIsSaving(true);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const dataToAdd = {
        nombre_prod: editData.nombre_prod,
        nombre_artesano: editData.nombre_artesano,
        precio: editData.precio,
        desc_medidas: editData.desc_medidas,
        desc_producto: editData.desc_producto,
        id_categoria: editData.id_categoria,
        id_emp: editData.id_emp,
      };

      // Realiza una solicitud POST para agregar un nuevo producto
      const response = await axios.post(`${apiUrl}/create-product`, dataToAdd, {
        headers,
      });

      // Agrega la nueva imagen a la lista de imágenes seleccionadas
      setSelectedProductImages([
        ...selectedProductImages,
        response.data.imagen,
      ]);

      setOpenEditDialog(false);
      fetchData();
    } catch (error) {
      console.error("Error adding data:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Fade>
      <Container>
        <Typography textAlign="center" variant="h4" gutterBottom>
          PRODUCTOS
        </Typography>
        <Typography textAlign="left" variant="h7" gutterBottom>
          Items Por Página:  
        </Typography>
        <FormControl>
          <Select
            sx={{ height: "25px", backgroundColor: "#ffffff", mb: 1 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>

        <Paper elevation={10}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: "#f8f9fa" }}>
                  <CenteredCell>ID</CenteredCell>
                  <CenteredCell>Artesano</CenteredCell>
                  <CenteredCell>Nombre</CenteredCell>
                  <CenteredCell>Precio</CenteredCell>
                  <CenteredCell>Descripción</CenteredCell>
                  <CenteredCell>Imágenes</CenteredCell>
                  <CenteredCell>Acciones</CenteredCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productsData.length === 0 ? (
                  <TableRow>
                    <TableCell>
                      <Skeleton animation="wave" height={30} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={30} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={30} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={30} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={30} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={30} />
                    </TableCell>
                    <TableCell>
                      <Skeleton animation="wave" height={30} />
                    </TableCell>
                  </TableRow>
                ) : (
                  productsData.map((product, index) => (
                    <TableRow key={product.id_producto}>
                      <CenteredCell>
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </CenteredCell>
                      <CenteredCell>{product.nombre_prod}</CenteredCell>
                      <CenteredCell>{product.nombre_artesano}</CenteredCell>
                      <CenteredCell>{product.precio}</CenteredCell>
                      <CenteredCell>{product.desc_producto}</CenteredCell>
                      <CenteredCell sx={{ textAlign: "center" }}>
                        <Tooltip title="VER IMAGEN" placement="top" arrow>
                          <IconButton
                            sx={{ backgroundColor: "#ececec" }}
                            onClick={() => {
                              setSelectedProductId(product.id_producto); // Actualiza el ID del producto seleccionado
                              setOpenImageModal(true);
                            }}
                          >
                            <CollectionsIcon
                              sx={{
                                backgroundColor: "#ececec",
                                fontSize: "30px",
                              }}
                              color="primary"
                            />
                          </IconButton>
                        </Tooltip>
                      </CenteredCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Tooltip title="EDITAR" placement="top" arrow>
                          <IconButton
                            sx={{ margin: "5px", backgroundColor: "#eeeeee" }}
                            color="primary"
                            size="large"
                            onClick={() => handleEditClick(product)}
                          >
                            <EditIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="BORRAR" arrow>
                          <IconButton
                            sx={{ backgroundColor: "#eeeeee" }}
                            size="large"
                            color="error"
                            onClick={() =>
                              handleDeleteClick(product.id_producto)
                            }
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            color="primary"
            variant="outlined"
          />
        </Grid>
        <Dialog
          open={openImageModal}
          onClose={() => {
            setOpenImageModal(false);
            setSelectedImages([]);
          }}
          maxWidth="md"
          fullWidth
        >
          <ImageGallery
            open={openImageModal}
            forceReloadData={forceReloadData} // Pasa la función como prop
            loading={loading}
            productId={selectedProductId} // Pasa el ID del producto seleccionado
            onClose={() => {
              setOpenImageModal(false);
              setSelectedImages([]);
            }}
            onDeleteImage={(index) => {}}
            onAddImage={() => {
              forceReloadData();
            }}
          />
        </Dialog>

        <Dialog
          open={openEditDialog}
          onClose={handleEditDialogClose}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>
            {editData.id_producto ? "Editar Producto" : "Agregar Producto"}
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Nombre"
              value={editData.nombre_prod}
              onChange={(e) =>
                setEditData({ ...editData, nombre_prod: e.target.value })
              }
              sx={{ mb: 2, marginTop: "10px" }}
            />
            <TextField
              fullWidth
              label="Artesano"
              value={editData.nombre_artesano}
              onChange={(e) =>
                setEditData({ ...editData, nombre_artesano: e.target.value })
              }
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Precio"
              value={editData.precio}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^\d*\.?\d*$/.test(inputValue)) {
                  setEditData({ ...editData, precio: inputValue });
                }
              }}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              multiline
              label="Medidas"
              value={editData.desc_medidas}
              onChange={(e) =>
                setEditData({ ...editData, desc_medidas: e.target.value })
              }
              sx={{ mb: 2 }}
            />

            <TextField
              fullWidth
              multiline
              rows={3}
              label="Descripción"
              value={editData.desc_producto}
              onChange={(e) =>
                setEditData({ ...editData, desc_producto: e.target.value })
              }
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              sx={{ mb: 2 }}
              select
              label="Categoría"
              value={editData.id_categoria}
              onChange={(e) =>
                setEditData({ ...editData, id_categoria: e.target.value })
              }
            >
              {categories.map((category) => (
                <MenuItem
                  key={category.id_categoria}
                  value={category.id_categoria}
                >
                  {category.descripcion}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              select
              label="Emprendimiento"
              value={editData.id_emp}
              onChange={(e) =>
                setEditData({ ...editData, id_emp: e.target.value })
              }
            >
              {ventures.map((venture) => (
                <MenuItem key={venture.id_emp} value={venture.id_emp}>
                  {venture.nombre_emp}
                </MenuItem>
              ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={
                editData.id_producto ? handleEditSubmit : handleAddSubmit
              }
              color="primary"
              disabled={isSaving}
            >
              {isSaving ? (
                <CircularProgress size={24} />
              ) : editData.id_producto ? (
                "Guardar"
              ) : (
                "Agregar"
              )}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
          <DialogTitle>Confirmar Eliminación</DialogTitle>
          <DialogContent>
            ¿Estás seguro de que deseas eliminar este producto? Esta acción no
            se puede deshacer.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteConfirm} color="error">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setOpenEditDialog(true)}
          >
            Agregar Producto
          </Button>
        </Grid>
      </Container>
    </Fade>
  );
}

export default Products;
