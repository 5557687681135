import { useParams } from "react-router";
import { Grid, Container } from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import fetchDataInvestigation from "infraestructura/api/fetchDataInvestigation";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CircularProgress from '@mui/material/CircularProgress';
import noImage from "assets/images/no-image.png";

const InfoInv = () => {
    const { id } = useParams();
    const { apiData, loading } = fetchDataInvestigation.FetchDataInvById(id);

    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
            <CircularProgress color="success" size={60} />
            </div>
        );
    }

    const carouselItems = apiData.imagenes.map((image, index) => ({
        src: image,
        alt: `Imagen ${index + 1}`,
    }));

    return (
        <Container maxWidth="lg" fixed>
            <Grid container pt={8}>
                <MKBox
                    bgColor="white"
                    borderRadius="xl"
                    shadow="lg"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    mb={3}
                >
                    <MKBox
                        variant="gradient"
                        bgColor="secondary"
                        coloredShadow="dark"
                        borderRadius="lg"
                        p={2}
                        mx={2}
                        mt={-3}
                    >
                        <MKTypography align="center" variant="h5" color="white">
                            {apiData.titulo}
                        </MKTypography>
                    </MKBox>

                    <MKBox p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <MKBox display="flex" alignItems="center">
                                    <MKTypography
                                        variant="subtitle1"
                                        component="div"
                                        align="center"
                                        sx={{
                                            fontSize: {
                                                xs: "0.9rem",
                                                sm: "1rem",
                                                md: "1.1rem",
                                                lg: "1.3rem",
                                            },
                                            textAlign: "justify",
                                            textJustify: "inter-word",
                                        }}
                                    >
                                        {apiData.descripcion}
                                    </MKTypography>
                                </MKBox>
                            </Grid>
                        </Grid>
                    </MKBox>

                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid
                            xs={12}
                            sm={8}
                            md={6}
                            sx={{
                                filter: "drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.5))",
                                pt: 2,
                                pb: 4,
                            }}
                        >
                            {carouselItems.length > 0 ? (
                                <Carousel
                                    indicators={false}
                                    showIndicators={false}
                                    infiniteLoop
                                    autoPlay
                                    interval={3000}
                                    swipeable={false}
                                    showStatus={false}
                                    showThumbs={false}
                                >
                                    {carouselItems.map((item, index) => (
                                        <MKBox
                                            component="img"
                                            key={index}
                                            src={item.src}
                                            alt={item.alt}
                                            sx={{ borderRadius: "20px",
                                            maxHeight:{xs:'200px',md:'400px'},
                                        }}
                                        />
                                    ))}
                                </Carousel>
                            ) : (
                                <img src={noImage} alt="Sin imágenes" />
                            )}
                        </Grid>
                    </Grid>
                </MKBox>
            </Grid>
        </Container>
    );
};

export default InfoInv;
