import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import fetchDataProducts from "infraestructura/api/fetchDataProducts";
import _noImage from "assets/images/no-image.png";
import CircularProgress from '@mui/material/CircularProgress';

function getCardImages(product) {
  const noImage = _noImage;
  const frontImage = product.imagenes[0] || noImage;
  const backImage =
    product.imagenes.length > 1 ? product.imagenes[1] : frontImage;
  return { frontImage, backImage };
}

function Products() {
  const { id } = useParams();
  const { apiData, loading } = fetchDataProducts.FetchDataProdByVenture(id);

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "10vh" }}>
        <CircularProgress color="success" size={60} />
      </div>
    );
  }

  return (
    <MKBox component="section" py={2} mb={4}>
      <Container>
        <Grid container item xs={12} lg={6}>
          <MKTypography variant="h3" fontWeight="light" mb={4}>
            Descubre los productos que tenemos
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          {Array.isArray(apiData) && apiData.length > 0 ? (
            apiData.map((product) => {
              const { frontImage, backImage } = getCardImages(product);

              return (
                <Grid key={product.id_producto} item xs={12} sm={6} lg={4}>
                  <RotatingCard>
                    <RotatingCardFront image={frontImage} />
                    <RotatingCardBack
                      image={backImage}
                      title={product.nombre_prod}
                      action={{
                        type: "internal",
                        route: `/products/${product.id_producto}`,
                        label: "Ver detalle",
                      }}
                    />
                  </RotatingCard>
                </Grid>
              );
            })
          ) : (
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={6} lg={4}>
                <img
                  src={_noImage}
                  alt="No hay productos"
                  style={{
                    width: "200px",
                    height: "200px",
                    alignItems: "center",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <MKTypography variant="h6" color="success" align="center">
                  Este emprendimiento aún no tiene productos
                </MKTypography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Products;
