/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";

// @mui icons
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";

// Material Kit 2 React components
import Grow from "@mui/material/Grow";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";

// Images
import bgImage from "assets/images/Raices.jpg";
import logo from "assets/images/logo-raices.png";

import useAuth from "utils/hooks/useAuth";
import { Typography } from "@mui/material";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showWelcome, setShowWelcome] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if ([username, password].includes("")) {
      alert("Todos los campos son obligatorios");
      return;
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/login`,
        {
          username,
          password,
        }
      );

      localStorage.setItem("token_raices", data.token);
      localStorage.setItem("user_name", data.username);
      setAuth(data);
      setLoginSuccess(true);
      setTimeout(() => {
        window.location.href = "/admin";
      }, 2000);
    } catch (error) {
      const err = error.response.data.error;
      alert(err);
    }
  };

  useEffect(() => {
    // Mostrar la animación de bienvenida si el inicio de sesión fue exitoso
    if (loginSuccess) {
      setShowWelcome(true);

      const timeout = setTimeout(() => {
        setShowWelcome(false);
      }, 5000);

      // Limpiar el temporizador si el componente se desmonta
      return () => clearTimeout(timeout);
    }
  }, [loginSuccess]);

  return (
    <>
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        px={1}
        py={2}
        width="100%"
        height="100vh"
        mx="auto"
        position="relative"
        zIndex={2}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                textAlign="center"
              >
                <MKTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Iniciar Sesión
                </MKTypography>
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  sx={{ mt: 1, mb: 1 }}
                >
                  <Grid item xs={2}>
                    <MKTypography
                      component={MuiLink}
                      href="/ventures"
                      target="_blank"
                      variant="body1"
                      color="white"
                    >
                      <StorefrontRoundedIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <MKTypography
                      component={MuiLink}
                      href="/products"
                      target="_blank"
                      variant="body1"
                      color="white"
                    >
                      <ShoppingBagRoundedIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                  <Grid item xs={2}>
                    <MKTypography
                      component={MuiLink}
                      href="/investigations"
                      target="_blank"
                      variant="body1"
                      color="white"
                    >
                      <DescriptionRoundedIcon color="inherit" />
                    </MKTypography>
                  </Grid>
                </Grid>
              </MKBox>

              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form" onSubmit={handleSubmit}>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Username"
                      fullWidth
                      value={username}
                      onChange={handleUsernameChange}
                    />
                  </MKBox>

                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      label="Contraseña"
                      fullWidth
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </MKBox>

                  <MKBox mt={4} mb={1}>
                    <MKButton
                      type="submit"
                      variant="gradient"
                      color="success"
                      size="large"
                      fullWidth
                    >
                      Iniciar Sesión <LoginRoundedIcon sx={{ ml: "6px" }} />
                    </MKButton>
                  </MKBox>

                  <MKBox textAlign="center" mb={2}>
                    <a href="/" target="_blank">
                      <img src={logo} alt="Logo raíces" width="50%" />
                    </a>
                  </MKBox>
                  {/* Animación de bienvenida usando Grow y Typography */}
                  {showWelcome && (
                    <Grow in={showWelcome}>
                      <Typography variant="h5" color="black" align="center">
                        ¡Bienvenido😉, {username}!
                      </Typography>
                    </Grow>
                  )}
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox
        width="100%"
        position="absolute"
        zIndex={2}
        bottom="1.625rem"
      ></MKBox>

      <Box width="100%" zIndex={2} position="fixed" bottom={0}>
      </Box>
    </>
  );
}

export default Login;
