import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
  TextField,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import Skeleton from "@mui/material/Skeleton";
import CollectionsIcon from "@mui/icons-material/Collections";
import ImgTowns from "./IMAGE/ImgTowns";
import { Fade } from "react-awesome-reveal";

const token = localStorage.getItem("token_raices");

const apiUrl = `${process.env.REACT_APP_API_URL}/towns`;

function Towns() {
  const theme = useTheme();
  const [reloadData, setReloadData] = useState(false);
  const [selectedTownsId, setSelectedTownsId] = useState(null);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [openAddDialog, setOpenAddDialog] = useState(false); // Agrega esta línea para definir el estado
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [towns, setTowns] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // Nuevo estado para el diálogo de eliminación
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [loading, setLoading] = useState(true);
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [deleteId, setDeleteId] = useState(null); // Nuevo estado para guardar el ID de la categoría a eliminar
  const [isSaving, setIsSaving] = useState(false);

     // Función para forzar la recarga de datos
     const forceReloadData = () => {
      setReloadData(!reloadData);
    };
  useEffect(() => {
    fetchData();
  }, [reloadData]);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl);
      setTowns(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditClick = (id, name, description) => {
    setEditId(id);
    setEditName(name);
    setEditDescription(description);
    setOpenEditDialog(true);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setEditId(null);
    setEditName("");
    setEditDescription("");
  };

  const handleEditSubmit = async () => {
    try {
      setIsSaving(true); // Iniciar la animación de carga
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const requestData = {
        nombre: editName,
        descripcion: editDescription,
      };

      await axios.patch(`${apiUrl}/edit/${editId}`, requestData, { headers });

      handleEditDialogClose();
      fetchData();
    } catch (error) {
      console.error("Error editing data:", error);
    } finally {
      setIsSaving(false); // Detener la animación de carga
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${apiUrl}/delete/${deleteId}`, { headers });

      handleDeleteDialogClose();
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleAddDialogOpen = () => {
    setOpenAddDialog(true);
  };

  const handleAddDialogClose = () => {
    setOpenAddDialog(false);
    setNewName("");
    setNewDescription("");
  };

  const handleAddSubmit = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const requestData = {
        nombre: newName,
        descripcion: newDescription,
      };

      await axios.post(`${apiUrl}/create`, requestData, { headers });

      handleAddDialogClose();
      fetchData();
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };

  return (
    <Fade>
    <Container>
      <Typography textAlign="center" variant="h4" gutterBottom>
        PUEBLOS
      </Typography>
      <Paper elevation={10}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{bgcolor:'#f8f9fa'}}>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                {isMobile ? null : <TableCell>Descripción</TableCell>}
                <TableCell>Imágenes</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell>
                    <Skeleton animation="wave" height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={30} />
                  </TableCell>
                  {isMobile ? null : (
                    <TableCell>
                      <Skeleton animation="wave" height={30} />
                    </TableCell>
                  )}
                  <TableCell>
                    <Skeleton animation="wave" height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={30} />
                  </TableCell>
                </TableRow>
              ) : (
                towns.map((town, index) => (
                  <TableRow key={town.id_pueblo}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{town.nombre}</TableCell>
                    {isMobile ? null : (
                      <TableCell>
                        {expandedDescriptions[town.id_pueblo] ||
                        town.descripcion.length <= 300 ? (
                          <>
                            {town.descripcion}
                            {town.descripcion.length > 300 && (
                              <Button
                                variant="text"
                                size="small"
                                onClick={() =>
                                  setExpandedDescriptions((prevState) => ({
                                    ...prevState,
                                    [town.id_pueblo]: false,
                                  }))
                                }
                              >
                                Ver menos
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {town.descripcion.substring(0, 300)}...
                            <Button
                              variant="text"
                              size="small"
                              onClick={() =>
                                setExpandedDescriptions((prevState) => ({
                                  ...prevState,
                                  [town.id_pueblo]: true,
                                }))
                              }
                            >
                              Ver más
                            </Button>
                          </>
                        )}
                      </TableCell>
                    )}
                    <TableCell sx={{ textAlign: "center" }}>
                      <Tooltip title="VER" placement="top" arrow>
                        <IconButton sx={{ backgroundColor: "#ececec" }}>
                          <CollectionsIcon
                            sx={{ backgroundColor: "#ececec",fontSize:"30px" }}
                            color="primary"
                            onClick={() => {
                              setSelectedTownsId(town.id_pueblo);
                              setOpenImageModal(true);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell sx={{ textAlign: "center" }}>
                      <Tooltip title="EDITAR" placement="top" arrow>
                        <IconButton
                          sx={{ margin: "5px", backgroundColor: "#eeeeee" }}
                          color="primary"
                          size="large"
                          onClick={() =>
                            handleEditClick(
                              town.id_pueblo,
                              town.nombre,
                              town.descripcion
                            )
                          }
                        >
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="BORRAR" arrow>
                        <IconButton
                          sx={{ backgroundColor: "#eeeeee" }}
                          size="large"
                          color="error"
                          onClick={() => handleDeleteClick(town.id_pueblo)}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Grid container justifyContent="center" sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddDialogOpen}
        >
          Agregar Pueblo
        </Button>
      </Grid>
      <Dialog
        open={openImageModal}
        onClose={() => {
          setOpenImageModal(false);
          setSelectedImages([]);
        }}
        maxWidth="md"
        fullWidth
      >
        <ImgTowns
          open={openImageModal}
          forceReloadData={forceReloadData} // Pasa la función como prop
          loading={loading}
          Townsid={selectedTownsId} // Pasa el ID del producto seleccionado
          onClose={() => {
            setOpenImageModal(false);
            setSelectedImages([]);
          }}
          onDeleteImage={(index) => {
          }}
          onAddImage={() => {
            forceReloadData();
          }}
        />
      </Dialog>

      <Dialog
        maxWidth="md"
        fullWidth
        open={openEditDialog}
        onClose={handleEditDialogClose}
      >
        <DialogTitle>Editar Pueblo</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Nombre"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            sx={{ mb: 2, marginTop: "10px" }}
          />
          <TextField
            fullWidth
            multiline
            rows={13}
            label="Descripción"
            value={editDescription}
            onChange={(e) => setEditDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleEditSubmit}
            color="primary"
            disabled={isSaving}
          >
            {isSaving ? <CircularProgress size={24} /> : "Guardar"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          ¿Estás seguro de que deseas eliminar este pueblo?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAddDialog} onClose={handleAddDialogClose}>
        <DialogTitle>Agregar nuevo pueblo</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAddSubmit} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
    </Fade>
  );
}

export default Towns;
