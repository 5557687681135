import { useState, useEffect } from "react";

const fetchDataVenture = {
  FetchDataAllVentures: () => {
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/ventures`
          );

          const data = await response.json();
          setApiData(data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      fetchDataFromApi();
    }, []);

    return { apiData, loading };
  },

  FetchDataVentById: (id) => {
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/ventures/${id}`
          );

          const data = await response.json();
          setApiData(data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      fetchDataFromApi();
    }, [id]);

    return { apiData, loading };
  },

  FetchDataVentByCommunity: (id_pueblo) => {
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/ventures/ventures-town/${id_pueblo}`
          );

          const data = await response.json();
          setApiData(data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      fetchDataFromApi();
    }, [id_pueblo]);

    return { apiData, loading };
  },
};

export default fetchDataVenture;
