import React from "react";
import { Box } from "@mui/material";
import Login from "./SignIn/Login";

const AdminPage = () => {
  return (
    <>
      <Box>
        <Login />
      </Box>
    </>
  );
};

export default AdminPage;
