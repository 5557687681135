import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import "../SignIn/TuArchivoDeEstilos.css";
import { Link } from "react-router-dom";
import { Bounce } from "react-awesome-reveal";

const TarjetaPueblos = (props) => {
  const { nombre, numero, linkVerMas, icono, ultimoItemAgregado } = props;
  const [contador, setContador] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (contador < numero) {
        setContador(contador + 1);
      }
    }, 20); // Velocidad de la animación (puedes ajustarla)

    return () => clearInterval(interval);
  }, [contador, numero]);

  return (
    <Bounce>
    <Card
      sx={{
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "visible",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "White",
          width: "50px",
          height: "50px",
          borderRadius: "50px",
          marginTop: "-15px",
          marginLeft: "-15px",
          marginBottom: "-24px",
          boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.4)",
        }}
      >
        {icono}
      </div>
      <CardContent
        sx={{
          textAlign: "center",
          flex: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: 'auto',
        }}
      >
        <Typography variant="h5" component="div" color="#7a7a7a">
          {nombre}: <span style={{ color: "black" }}>{contador}</span>
        </Typography>
        <div
          style={{
            fontSize: "12px",
            borderRadius: "10px",
            textAlign: "center",
            marginBottom:'10px'
          }}
        >
          {ultimoItemAgregado ? (
            <>
              Recién añadido:{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                {ultimoItemAgregado}
              </span>
            </>
          ) : (
            <CircularProgress size={20} thickness={4} /> // Muestra el indicador de carga si ultimoItemAgregado no tiene valor
          )}
        </div>
      </CardContent>
      <hr />
      <IconButton
        sx={{ borderRadius: "0", fontSize: "15px" }}
        variant="contained"
        color="#a6a6a7"
        component={Link}
        to={linkVerMas}
      >
        Ver más
      </IconButton>
    </Card>
    </Bounce>
  );
};

export default TarjetaPueblos;
