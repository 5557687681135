import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
import MUIButton from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import styled from "styled-components";
import Skeleton from "@mui/material/Skeleton";
import { Fade } from "react-awesome-reveal";

const token = localStorage.getItem("token_raices");

const apiUrl = `${process.env.REACT_APP_API_URL}/categories`;

const StyledTypography = styled(Typography)`
  text-align: center;
`;

function Categories() {
  const StyledTableCell = styled(TableCell)`
    width: 33.3%;
    text-align: center;
    vertical-align: middle;
  `;

  const [categories, setCategories] = useState([]);
  const [descriptionError, setDescriptionError] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // Nuevo estado para el diálogo de eliminación
  const [deleteId, setDeleteId] = useState(null); // Nuevo estado para guardar el ID de la categoría a eliminar
  const [editId, setEditId] = useState(null);
  const [editDescription, setEditDescription] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditClick = (id, description) => {
    setEditId(id);
    setEditDescription(description);
    setOpenEditDialog(true);
    setDescriptionError(false); // Restablecer el estado de error a false

  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setEditId(null);
    setEditDescription("");
  };

  const handleEditSubmit = async () => {
    try {
      setIsSaving(true); // Iniciar la animación de carga
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const requestData = {
        descripcion: editDescription,
      };

      // Verifica si el campo está vacío
      if (editDescription.trim() === "") {
        setDescriptionError(true);
      } else {
        setDescriptionError(false);
      }

      // Si el campo está vacío, no envíes la solicitud
      if (descriptionError) {
        return;
      }

      if (editId) {
        await axios.patch(`${apiUrl}/edit-category/${editId}`, requestData, {
          headers,
        });
      } else {
        await axios.post(`${apiUrl}/create-category`, requestData, { headers });
      }

      handleEditDialogClose();
      fetchData();
    } catch (error) {
      console.error("Error editing data:", error);
    } finally {
      setIsSaving(false); // Detener la animación de carga
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
    setDeleteId(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${apiUrl}/delete-category/${deleteId}`, { headers });

      handleDeleteDialogClose();
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <Fade>
    <Container>
      <StyledTypography variant="h4" gutterBottom>
        CATEGORIAS
      </StyledTypography>
      <Paper elevation={10}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{bgcolor:'#f8f9fa'}}>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>Descripción</StyledTableCell>
                <StyledTableCell>Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.length === 0 ? (
                <TableRow>
                  <StyledTableCell>
                    <Skeleton animation="wave" height={30} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Skeleton animation="wave" height={30} />
                  </StyledTableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={30} />
                  </TableCell>
                </TableRow>
              ) : (
                categories.map((category, index) => (
                  <TableRow key={category.id_categoria}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{category.descripcion}</StyledTableCell>
                    <StyledTableCell>
                      <Tooltip title="EDITAR" placement="top" arrow>
                        <IconButton
                          sx={{ margin: "5px", backgroundColor: "#eeeeee" }}
                          size="large"
                          color="primary"
                          onClick={() =>
                            handleEditClick(
                              category.id_categoria,
                              category.descripcion
                            )
                          }
                        >
                          {" "}
                          <EditIcon fontSize="inherit" />{" "}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="BORRAR" arrow>
                        <IconButton
                          sx={{ backgroundColor: "#eeeeee" }}
                          size="large"
                          color="error"
                          onClick={() =>
                            handleDeleteClick(category.id_categoria)
                          }
                        >
                          {" "}
                          <DeleteIcon fontSize="inherit" />{" "}
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Grid container justifyContent="center" sx={{ mt: 3 }}>
        <MUIButton
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenEditDialog(true)}
        >
          Agregar Categoria
        </MUIButton>
      </Grid>
      <Dialog open={openEditDialog} onClose={handleEditDialogClose}>
        <DialogTitle>{editId ? "Editar" : "Agregar"} Categoria</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Descripción"
            multiline
            rows={3}
            value={editDescription}
            onChange={(e) => setEditDescription(e.target.value)}
            sx={{ marginTop: "10px" }}
            error={descriptionError} // Utiliza el estado de error
            helperText={descriptionError ? "La descripción es requerida" : ""} // Muestra el mensaje de error
          />
        </DialogContent>
        <DialogActions>
          <MUIButton onClick={handleEditDialogClose} color="primary">
            Cancelar
          </MUIButton>
          <MUIButton
            onClick={handleEditSubmit}
            color="primary"
            disabled={isSaving}
          >
            {isSaving ? <CircularProgress size={24} /> : "Guardar"}
          </MUIButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          ¿Estás seguro de que deseas eliminar esta categoría?
        </DialogContent>
        <DialogActions>
          <MUIButton onClick={handleDeleteDialogClose} color="primary">
            Cancelar
          </MUIButton>
          <MUIButton onClick={handleDeleteConfirm} color="error">
            Eliminar
          </MUIButton>
        </DialogActions>
      </Dialog>
    </Container>
    </Fade>
  );
}

export default Categories;