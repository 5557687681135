// useContactInfo.js

import { useState, useEffect } from "react";

const useContactInfo = () => {
  const [contactInfo, setContactInfo] = useState({});
  const [Cloading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/information/contact`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token_raices")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Error fetching contact information");
        }

        const data = await response.json();
        setContactInfo(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContactInfo();
  }, []);

  const editContactInfo = async (newPhoneNumber) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/information/edit-contact`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token_raices")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ telefono: newPhoneNumber }),
      });

      if (!response.ok) {
        throw new Error("Error editing contact information");
      }

      // Update the local state with the new phone number
      setContactInfo((prevContactInfo) => ({
        ...prevContactInfo,
        telefono: newPhoneNumber,
      }));
    } catch (error) {
      setError(error.message);
    }
  };

  return { contactInfo, Cloading, error, editContactInfo };
};

export default useContactInfo;
