import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { Container } from "@mui/material";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import PhoneIphoneRoundedIcon from "@mui/icons-material/PhoneIphoneRounded";
import RoomIcon from "@mui/icons-material/Room";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import GoogleMapContainer from "presentation/components/GoogleMapsContainer";
import { Fade } from "react-awesome-reveal";

function Contact() {
  return (
    <Container sx={{ textAlign: "center" }}>
      <Grid container spacing={4} alignItems="center" pb={3} py={3}>
        <Grid item xs={12} sx={{ py: 2 }}>
          <MKTypography
            variant="h2"
            sx={{
              fontSize: {
                xs: "2rem",
                sm: "2.5rem",
                md: "2.5rem",
              },
              textAlign: "center",
            }}
            component="div"
            mb={2}
          >
            CONTÁCTANOS
          </MKTypography>

          <MKTypography variant="h6" sx={{ textAlign: "center" }} mb={2}>
            Para cualquir duda, consulta o mayor informacion, comuníquese
            mediante un mensaje. Con gusto le estaremos respondiendo a la mayor
            brevedad posible.
          </MKTypography>
        </Grid>
        
        <Grid item xs={12} md={6}>
            <Fade>
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mb={3}
          >
            <MKBox
              variant="gradient"
              bgColor="success"
              coloredShadow="success"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h5" color="white">
                Información de contacto
              </MKTypography>
            </MKBox>

            <MKBox p={3}>
              <Grid container>
                <Grid item xs={12}>
                  <MKBox display="flex" alignItems="center">
                    <MailRoundedIcon sx={{ marginRight: "6px" }} />
                    <MKTypography
                      variant="subtitle1"
                      component="div"
                      align="left"
                      sx={{ textDecoration: "none" }}
                    >
                      <a
                        href="mailto:presidencia@iiap.gob.pe"
                        style={{ color: "inherit" }}
                      >
                        presidencia@iiap.gob.pe
                      </a>
                    </MKTypography>
                  </MKBox>
                </Grid>

                <Grid item xs={12}>
                  <MKBox display="flex" alignItems="center">
                    <PhoneIphoneRoundedIcon sx={{ marginRight: "6px" }} />
                    <MKTypography
                      variant="subtitle1"
                      component="div"
                      align="left"
                    >
                      65265515
                    </MKTypography>
                  </MKBox>
                </Grid>

                <Grid item xs={12}>
                  <MKBox display="flex" alignItems="center" sx={{marginBottom:'10px'}}>
                    <RoomIcon sx={{ marginRight: "6px" }} />
                    <MKTypography
                      variant="subtitle1"
                      component="div"
                      align="left"
                    >
                      Av. Abelardo Quiñones km. 2.5, Iquitos, Perú
                    </MKTypography>
                  </MKBox>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    filter: "drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.5))"
                  }}
                >
                  <GoogleMapContainer
                    lat={-3.7673853144298244}
                    lng={-73.27470462961259}
                  />
                </Grid>
              </Grid>
            </MKBox>
          </MKBox>
          </Fade>
        </Grid>

        <Grid item xs={12} md={6}>
            <Fade>
          <MKBox
            component="form"
            method="post"
            autoComplete="off"
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mb={3}
          >
            <MKBox
              variant="gradient"
              bgColor="success"
              coloredShadow="success"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h5" color="white">
                Envíanos un mensaje
              </MKTypography>
            </MKBox>

            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKInput
                    type="text"
                    variant="outlined"
                    label="Tú nombre"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    type="email"
                    variant="outlined"
                    label="Tú e-mail"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    type="tel"
                    variant="outlined"
                    label="Asunto"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    type="text"
                    variant="outlined"
                    label="Tú mensaje"
                    multiline
                    fullWidth
                    rows={4}
                  />
                </Grid>
              </Grid>

              <Grid container item justifyContent="center" xs={12} my={2}>
                <MKButton
                  type="submit"
                  variant="gradient"
                  color="success"
                  size="large"
                >
                  Enviar Mensaje <SendRoundedIcon sx={{ ml: "6px" }} />
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
          </Fade>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Contact;
