import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
  Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import CollectionsIcon from "@mui/icons-material/Collections";
import ImgVentures from "./IMAGE/ImgVentures";
import { Fade } from "react-awesome-reveal";

const CenteredCell = ({ children }) => (
  <TableCell
    sx={{
      fontSize: {
        xs: "0.8rem",
        sm: "0.8rem",
        md: "0.9rem",
        lg: "0.9rem",
      },
      textAlign: "justify",
    }}
  >
    {children}
  </TableCell>
);

const apiUrl = `${process.env.REACT_APP_API_URL}/ventures`;

function VenturesManagement() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [reloadData, setReloadData] = useState(false);
  const [ventures, setVentures] = useState([]);
  const [selectedventureId, setSelectedventureId] = useState(null);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [puebloError, setPuebloError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [editPueblo, setEditPueblo] = useState(123); // Default value, change as needed
  const [puebloName, setPuebloName] = useState(""); // State to hold the pueblo name
  const [pueblos, setPueblos] = useState([]); // State to hold the list of pueblos
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);

  const token = localStorage.getItem("token_raices");

    // Función para forzar la recarga de datos
    const forceReloadData = () => {
      setReloadData(!reloadData);
    };
  useEffect(() => {
    fetchData();
    fetchPueblos(); // Fetch the list of pueblos
  }, [reloadData]);

  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl);
      setVentures(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchPuebloName = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/towns/${id}`
      );
      setPuebloName(response.data.nombre);
    } catch (error) {
      console.error("Error fetching pueblo name:", error);
    }
  };
  const fetchPueblos = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/towns`
      );
      setPueblos(response.data);
    } catch (error) {
      console.error("Error fetching pueblos:", error);
    }
  };

  const handleEditClick = async (id, name, description, pueblo) => {
    setEditId(id);
    setEditName(name);
    setEditDescription(description);
    setEditPueblo(pueblo);
    await fetchPuebloName(pueblo); // Fetch pueblo name
    setDescriptionError(false);
    setNameError(false);
    setPuebloError(false);
    setOpenEditDialog(true);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setEditId(null);
    setEditName("");
    setEditDescription("");
    setEditPueblo("");
  };

  const handleEditSubmit = async () => {
    try {
      setIsSaving(true); // Iniciar la animación de carga
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const dataToUpdate = {
        nombre_emp: editName,
        descripcion: editDescription,
        id_pueblo: editPueblo,
      };
      // Validación del campo de pueblo
      if (!editPueblo) {
        setPuebloError(true);
      } else {
        setPuebloError(false);
      }

      if (editDescription.trim() === "") {
        setDescriptionError(true);
      } else {
        setDescriptionError(false);
      }

      if (descriptionError) {
      }
      if (!editName) {
        setNameError(true);
      } else {
        setNameError(false);
      }

      if (editId) {
        await axios.patch(`${apiUrl}/edit-venture/${editId}`, dataToUpdate, {
          headers,
        });
      } else {
        await axios.post(`${apiUrl}/create-venture`, dataToUpdate, {
          headers,
        });
      }

      handleEditDialogClose();
      fetchData();
    } catch (error) {
      console.error("Error editing data:", error);
    } finally {
      setIsSaving(false); // Detener la animación de carga
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteSubmit = async (id) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${apiUrl}/delete-venture/${id}`, { headers });

      handleDeleteDialogClose();
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleAddVenture = () => {
    setEditId(null);
    setEditName("");
    setEditDescription("");
    setEditPueblo("");
    setOpenEditDialog(true);
  };

  return (
    <Fade>
    <Container>
      <Typography
        textAlign="center"
        variant="body1"
        sx={{
          fontSize: {
            xs: "1.7rem",
            sm: "2rem",
          },
        }}
        gutterBottom
      >
        EMPRENDIMIENTOS
      </Typography>
      <Paper elevation={10}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{bgcolor:'#f8f9fa'}}>
                <CenteredCell>ID</CenteredCell>
                <CenteredCell>Nombre</CenteredCell>
                {isMobile ? null : <CenteredCell>Descripción</CenteredCell>}
                <CenteredCell>Imágenes</CenteredCell>
                <CenteredCell>Acciones</CenteredCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <CenteredCell>
                    <Skeleton animation="wave" height={30} />
                  </CenteredCell>
                  <CenteredCell>
                    <Skeleton animation="wave" height={30} />
                  </CenteredCell>
                  {isMobile ? null : (
                    <TableCell>
                      <Skeleton animation="wave" height={30} />
                    </TableCell>
                  )}
                  <TableCell>
                    <Skeleton animation="wave" height={30} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={30} />
                  </TableCell>
                </TableRow>
              ) : (
                ventures.map((venture, index) => (
                  <TableRow key={venture.id_emp}>
                    <CenteredCell>{index + 1}</CenteredCell>
                    <CenteredCell>{venture.nombre_emp}</CenteredCell>
                    {isMobile ? null : (
                      <CenteredCell>
                        {expandedDescriptions[venture.id_emp] ||
                        venture.descripcion.length <= 300 ? (
                          <>
                            {venture.descripcion}
                            {venture.descripcion.length > 300 && (
                              <Button
                                variant="text"
                                size="small"
                                onClick={() =>
                                  setExpandedDescriptions((prevState) => ({
                                    ...prevState,
                                    [venture.id_emp]: false,
                                  }))
                                }
                              >
                                Ver menos
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {venture.descripcion.substring(0, 300)}...
                            <Button
                              variant="text"
                              size="small"
                              onClick={() =>
                                setExpandedDescriptions((prevState) => ({
                                  ...prevState,
                                  [venture.id_emp]: true,
                                }))
                              }
                            >
                              Ver más
                            </Button>
                          </>
                        )}
                      </CenteredCell>
                    )}
                    <TableCell sx={{ textAlign: "center" }}>
                      <Tooltip title="VER IMAGEN" placement="top" arrow>
                        <IconButton sx={{ backgroundColor: "#ececec" }}>
                          <CollectionsIcon
                            sx={{ backgroundColor: "#ececec",fontSize:"30px" }}
                            color="primary"
                            onClick={() => {
                              setSelectedventureId(venture.id_emp);
                              setOpenImageModal(true);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell sx={{ textAlign: "center" }}>
                      <Tooltip title="EDITAR" placement="top" arrow>
                        <IconButton
                          sx={{ margin: "5px", backgroundColor: "#eeeeee" }}
                          color="primary"
                          size="large"
                          onClick={() =>
                            handleEditClick(
                              venture.id_emp,
                              venture.nombre_emp,
                              venture.descripcion,
                              venture.id_pueblo
                            )
                          }
                        >
                          <EditIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="BORRAR" arrow>
                        <IconButton
                          sx={{ backgroundColor: "#eeeeee" }}
                          size="large"
                          color="error"
                          onClick={() => handleDeleteClick(venture.id_emp)}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Grid container justifyContent="center" sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddVenture}
        >
          Agregar Emprendimiento
        </Button>
      </Grid>
      <Dialog
        open={openImageModal}
        onClose={() => {
          setOpenImageModal(false);
          setSelectedImages([]);
        }}
        maxWidth="md"
        fullWidth
      >
        <ImgVentures
         open={openImageModal}
         forceReloadData={forceReloadData} // Pasa la función como prop
         loading={loading}
         ventureId={selectedventureId} // Pasa el ID del producto seleccionado
         onClose={() => {
           setOpenImageModal(false);
           setSelectedImages([]);
         }}
         onDeleteImage={(index) => {
         }}
         onAddImage={() => {
           forceReloadData();
         }}
       />
      </Dialog>
      
      <Dialog
        open={openEditDialog}
        onClose={handleEditDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editId ? "Editar Emprendimiento" : "Agregar Emprendimiento"}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Nombre"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            sx={{ mb: 2, marginTop: "10px" }}
            error={nameError}
            helperText={nameError ? "El nombre es requerido" : ""}
          />
          <TextField
            fullWidth
            multiline
            rows={13}
            label="Descripción"
            value={editDescription}
            onChange={(e) => setEditDescription(e.target.value)}
            sx={{ mb: 2 }}
            error={descriptionError} // Utiliza el estado de error
            helperText={descriptionError ? "La descripción es requerida" : ""} // Muestra el mensaje de error
          />

          <TextField
            fullWidth
            select
            label="Pueblo"
            value={editPueblo}
            onChange={(e) => setEditPueblo(e.target.value)}
            error={puebloError}
            helperText={puebloError ? "Debes seleccionar un pueblo" : ""}
          >
            {pueblos.map((pueblo) => (
              <MenuItem key={pueblo.id_pueblo} value={pueblo.id_pueblo}>
                {pueblo.nombre}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleEditSubmit}
            color="primary"
            disabled={isSaving}
          >
            {isSaving ? <CircularProgress size={24} /> : "Guardar"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
        <DialogTitle>Confirmar Eliminación</DialogTitle>
        <DialogContent>
          ¿Estás seguro de que quieres borrar este emprendimiento?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleDeleteSubmit(deleteId)} color="error">
            Borrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
    </Fade>
  );
}

export default VenturesManagement;
