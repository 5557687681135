import React, { useState, useEffect } from "react";
import { Container, Typography, Paper, Grid, Button } from "@mui/material";
import MUIButton from "@mui/material/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import EditIcon from "@mui/icons-material/Edit"; // Agregado: importar EditIcon

function Information() {
  const [aboutUsData, setAboutUsData] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  // const { token } = useAuth(); // Obtén el token del contexto de autenticación
  const token = localStorage.getItem("token_raices");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/information/aboutUs`
      );
      setAboutUsData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditClick = () => {
    setEditTitle(aboutUsData.titulo);
    setEditDescription(aboutUsData.desc_nosotros);
    setOpenEditDialog(true);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setEditTitle("");
    setEditDescription("");
  };

  const handleEditSubmit = async () => {
    try {
      setIsSaving(true); // Iniciar la animación de carga
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const dataToUpdate = {
        titulo: editTitle,
        desc_nosotros: editDescription,
      };

      await axios.patch(
        `${process.env.REACT_APP_API_URL}/information/edit-aboutUs`,
        dataToUpdate,
        { headers }
      );

      handleEditDialogClose();
      fetchData();
    } catch (error) {
      console.error("Error editing data:", error);
    } finally {
      setIsSaving(false); // Detener la animación de carga
    }
  };

  // Dividir la descripción en partes
  const descriptionParts = aboutUsData
    ? aboutUsData.desc_nosotros.split("\\n")
    : [];

  return (
    <Container mt={4}>
      <Typography textAlign="center" variant="h4" gutterBottom>
        {aboutUsData ? aboutUsData.titulo : <Skeleton animation="wave" />}
      </Typography>
      {descriptionParts.length > 0 ? (
        descriptionParts.map((part, index) => (
          <Paper
            sx={{textAlign:'center'}}
            key={index}
            elevation={20}
            style={{ padding: "20px", marginBottom: "20px" }}
          >
            <Typography variant="body1">{part}</Typography>
          </Paper>
        ))
      ) : (
        <Skeleton animation="wave" height={150} />
      )}
      <Grid container justifyContent="center" sx={{ mt: 3 }}>
        <Button
          variant="contained"
          startIcon={<EditIcon />}
          onClick={handleEditClick}
        >
          Editar Información
        </Button>
      </Grid>
      <Dialog             fullWidth
 open={openEditDialog} onClose={handleEditDialogClose}>
        <DialogTitle>Editar Informacion</DialogTitle>
        <DialogContent>
        <div style={{backgroundColor:'#eeeeee',borderRadius:'10px', color: "red", textAlign:'center' }}>
            NOTA:{" "}
            <spam style={{ color: "black" }}>
              La descripción está separada por el símbolo: \n 
            </spam>
          </div>
          <TextField
            fullWidth
            label="Título"
            value={editTitle}
            onChange={(e) => setEditTitle(e.target.value)}
            sx={{ mb: 2, marginTop: "10px" }}
          />
          <TextField
            fullWidth
            multiline
            rows={20}
            label="Descripción"
            value={editDescription}
            onChange={(e) => setEditDescription(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <MUIButton onClick={handleEditDialogClose} color="primary">
            Cancelar
          </MUIButton>
          <Button
            onClick={handleEditSubmit}
            color="primary"
            disabled={isSaving}
          >
            {isSaving ? <CircularProgress size={24} /> : "Guardar"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Information;
