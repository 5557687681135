import MKTypography from "components/MKTypography";
import {
  Grid,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import MKButton from "components/MKButton";
import fetchDataInvestigation from "infraestructura/api/fetchDataInvestigation";
import { Fade, Bounce } from "react-awesome-reveal";

function Investigation() {
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const { apiData } = fetchDataInvestigation.FetchDataAllInvestigation();

  const CustomCard = ({ image, title, link }) => {
    return (
      <Card
        sx={{
          filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2))",
          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.05)",
          }
        }}
      >
        <CardActionArea href={link}>
          <CardMedia
            component="img"
            image={image}
            alt={title}
            sx={{ width: "100%", height:{xs:'20vh',md:'20vh',lg:'20vh'}, objectPosition: "center 35%" }}
          />
          <CardContent
            sx={{
              width: "100%",
              height: {xs:'9rem', sm: "10rem", xl: "8rem" },
              display: "flex",
              alignItems: "center",
            }}
          >
            <MKTypography
              sx={{
                fontSize: {
                  xs: "0.8rem",
                  md: "0.9rem",
                  lg: "0.9rem",
                },
                textAlign: "center",
                mb: "-10px",
              }}
              variant="h2"
              gutterBottom
              component="div"
            >
              {title}
            </MKTypography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  return (
    <>
        <Container maxWidth="lg" sx={{ py: 2, mt: "25px" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              sx={{
                order: isMdScreen ? 2 : 1,
              }}
            >
              <Grid container justifyContent="center" spacing={4}>
                {apiData.map((investigation) => (
                  <Grid
                    key={investigation.id_investigacion}
                    item
                    xs={12}
                    md={6}
                  >
                    <Bounce>
                      <CustomCard
                        link={`/investigation/${investigation.id_investigacion}`}
                        image={investigation.imagenes[0]}
                        title={investigation.titulo}
                      />
                    </Bounce>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                order: isMdScreen ? 1 : 2,
              }}
            >
              <Grid item xs={12} md={10}>
                <MKTypography
                  variant="h2"
                  sx={{
                    fontSize: {
                      xs: "1.8rem",
                      sm: "2.3rem",
                      md: "2.1rem",
                    },
                    textAlign: "center",
                  }}
                  component="div"
                  mb={2}
                >
                  INVESTIGACIONES
                </MKTypography>

                <MKTypography
                  variant="h5"
                  sx={{
                    fontSize: {
                      xs: "0.9rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "1rem",
                    },
                    textAlign: { xs: "center", md: "justify" },
                  }}
                  mb={2}
                >
                  La investigación participativa es sumamente importante cuando
                  hablamos de la recuperación y revalorización de las
                  manifestaciones culturales de los pueblos indígenas
                  amazónicos.
                </MKTypography>
                <Fade>
                  <Card
                    sx={{
                      mb: 2,
                      py: 1,
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.5)", 
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={4}>
                        <Grid item container xs={12}>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "0.9rem",
                                  sm: "1rem",
                                  md: "1rem",
                                  lg: "1rem",
                                },
                                textAlign: "justify",
                                display: { xs: "none", md: "block" },
                              }}
                              variant="body1"
                              color="text.secondary"
                            >
                              Ayuda a identificar y documentar manifestaciones
                              culturales en riesgo o pasadas por alto. Su
                              resultado contribuye a preservar y difundir estas
                              manifestaciones, fundamentales para la identidad
                              cultural de los pueblos amazónicos. Además, ayuda
                              a comprender la importancia de estas
                              manifestaciones, su conexión con los ecosistemas y
                              su relevancia para las comunidades y la sociedad
                              en general. La investigación participativa permite
                              rescatar las manifestaciones culturales en peligro
                              y promueve la transmisión generacional en las
                              comunidades. En el caso específico de "Raíces del
                              bosque", la investigación participativa con grupos
                              de mujeres indígenas en sus propias comunidades es
                              crucial.
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "0.9rem",
                                  sm: "1rem",
                                  md: "1rem",
                                  lg: "1.1rem",
                                },
                                textAlign: "center",
                                display: { xs: "block", md: "none" },
                              }}
                              variant="body1"
                              color="text.secondary"
                            >
                              La investigación participativa preserva
                              manifestaciones culturales amenazadas, fortalece
                              identidades y comunidades, y promueve la
                              transmisión generacional, especialmente en
                              comunidades indígenas como "Raíces del Bosque".
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Grid
                    justifyContent="center"
                    mt={4}
                    sx={{ textAlign: "center", mb: "25px",display:{xs:'none',md:'block'} }}
                  >
                    <Link
                      to="/investigations"
                      style={{ textDecoration: "none" }}
                    >
                      <MKButton variant="outlined" color="success" size="large">
                        Ver todas las Investigaciones
                      </MKButton>
                    </Link>
                  </Grid>
                </Fade>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            justifyContent="center"
            mt={4}
            sx={{ textAlign: "center", mb: "25px",display:{xs:'block',md:'none'} }}
          >
            <Link to="/investigations" style={{ textDecoration: "none" }}>
              <MKButton variant="outlined" color="success" size="large">
                Ver todas las Investigaciones
              </MKButton>
            </Link>
          </Grid>
        </Container>
    </>
  );
}

export default Investigation;
