import React from 'react';
import Box from '@mui/material/Box';
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import im1 from "./footer/img.jpg";
import im2 from "./footer/1img.jpg";
import im3 from "./footer/2img.jpg";
import im4 from "./footer/3img.jpg";

function Footer({ links, socials, light }) {
  const renderLinks = links.map((link) => (
    <MKTypography
      key={link.name}
      component={Link}
      href={link.href}
      target="_blank"
      variant="body2"
      color={light ? "white" : "secondary"}
      fontWeight="regular"
    >
      {link.name}
    </MKTypography>
  ));

  const renderSocials = socials.map((social) => (
    <MKTypography
      key={social.link}
      component={Link}
      href={social.link}
      target="_blank"
      variant="body2"
      color={light ? "white" : "secondary"}
      fontWeight="regular"
    >
      {social.icon}
    </MKTypography>
  ));

  const images = [im1, im2, im3, im4];

  return (
    <MKBox
      component="footer"
      pt={6}
      sx={{
        flexGrow: 1,
        height: { xs: "100vh", md: "100%", lg: "100%" },
        backgroundImage: {
          xs: "url(/images/foomobile.png)",
          md: "url(/images/footablet.png)",
          lg: "url(/images/footer.png)",
        },
        backgroundSize: { xs: "cover", lg: "100% 100%" },
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        filter: "brightness(0.8)",
      }}
    >
      <Grid padding={{xs:'10px',md:'50px'}} container justifyContent="center" sx={{ pt: { sm: 3, xxl: 10 } }}>
        <Grid item xs={10} lg={8}>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            spacing={{ xs: 2, lg: 3, xl: 6 }}
            mb={3}
          >
            {renderLinks}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Stack
            display="flex"
            direction="row"
            justifyContent="center"
            spacing={3}
            mt={1}
            mb={3}
          >
            {renderSocials}
          </Stack>
        </Grid>
        <Grid container lg={8} spacing={{xs:2, lg:2}}>
          {images.map((image, index) => (
            <Grid item xs={12} md={6} lg={3} key={index}>
              <img
                src={image}
                alt={`Imagen ${index + 1}`}
                height={80}
                style={{ display: 'block', margin: '0 auto', maxWidth: '100%', borderRadius: '5px' }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </MKBox>
  );
}

Footer.defaultProps = {
  links: [
    { href: "products", name: "Productos" },
    { href: "ventures", name: "Emprendimientos" },
    { href: "investigations", name: "Investigaciones" },
  ],
  socials: [
    {
      icon: <FacebookIcon fontSize="small" />,
      link: "https://www.facebook.com/IIAPPERU/?locale=es_LA",
    },
    {
      icon: <TwitterIcon fontSize="small" />,
      link: "https://twitter.com/IiapPeru",
    },
    {
      icon: <InstagramIcon fontSize="small" />,
      link: "https://www.instagram.com/iiapperu/?hl=es",
    },
    {
      icon: <EmailIcon fontSize="small" />,
      link: "mailto:presidencia@iiap.gob.pe",
    },
  ],
  light: true,
};

export default Footer;
