import { useState, useEffect } from "react";

const fetchDataCommunity = {
  FetchDataAllCommunities: () => {
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/towns`
          );
          
          const data = await response.json();
          setApiData(data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      fetchDataFromApi();
    }, []);

    return { apiData, loading };
  },

  FetchDataCommById: (id) => {    
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/towns/${id}`
          );

          const data = await response.json();
          setApiData(data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      fetchDataFromApi();
    }, [id]);

    return { apiData, loading };
  },
};

export default fetchDataCommunity;
