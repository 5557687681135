import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./presentation/pages/HomePage";
import VentureListPage from "./presentation/pages/VentureListPage";
import VenturePage from "./presentation/pages/VenturePage";
import CommunityPage from "presentation/pages/CommunityPage";
import InvestigationListPage from "presentation/pages/InvestigationListPage";
import InvestigationPage from "./presentation/pages/InvestigationPage";
import ProducstListPage from "./presentation/pages/ProductListPage";
import ProductPage from "./presentation/pages/ProductPage";
import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import Template from "presentation/layout/Template";
import TemplateAdmin from "presentation/layout/TemplateAdmin";
import TemplateLogin from "presentation/layout/TemplateLogin";
import LoginPage from "presentation/components/Administrador/LoginPage";
import Dashboard from "presentation/components/Administrador/dashboard/Dashboard";
import Categories from "presentation/components/Administrador/dashboard/Categories";
import Information from "presentation/components/Administrador/dashboard/Information";
import Products from "presentation/components/Administrador/dashboard/Products";
import Investigation from "presentation/components/Administrador/dashboard/Investigation";
import Towns from "presentation/components/Administrador/dashboard/Towns";
import Ventures from "presentation/components/Administrador/dashboard/Ventures";

import { AuthProvider } from "context/AuthProvider";

function App() {
  return (
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box sx={{ background: "#f0f2f5" }}>
              <BrowserRouter>
                  <AuthProvider>
                      <Routes>
                          <Route path="/" element={<Template />}>
                              <Route index element={<HomePage />} />
                              <Route
                                  path="/ventures"
                                  element={<VentureListPage />}
                              />
                              <Route
                                  path="/ventures/:id"
                                  element={<VenturePage />}
                              />
                              <Route
                                  path="/community/:id"
                                  element={<CommunityPage />}
                              />
                              <Route
                                  path="/investigations"
                                  element={<InvestigationListPage />}
                              />
                              <Route
                                  path="/investigation/:id"
                                  element={<InvestigationPage />}
                              />
                              <Route
                                  path="/products"
                                  element={<ProducstListPage />}
                              />
                              <Route
                                  path="/products/:id"
                                  element={<ProductPage />}
                              />
                          </Route>

                          <Route path="/login" element={<TemplateLogin />}>
                              <Route index element={<LoginPage />} />
                          </Route>

                          <Route path="/admin" element={<TemplateAdmin />}>
                              <Route index element={<Dashboard />} />
                              <Route
                                  path="categories"
                                  element={<Categories />}
                              />
                              <Route
                                  path="information"
                                  element={<Information />}
                              />
                              <Route path="products" element={<Products />} />
                              <Route
                                  path="investigation"
                                  element={<Investigation />}
                              />
                              <Route path="towns" element={<Towns />} />
                              <Route path="ventures" element={<Ventures />} />
                          </Route>

                          <Route path="/*" element={<HomePage />}></Route>
                      </Routes>
                  </AuthProvider>
              </BrowserRouter>
          </Box>
      </ThemeProvider>
  );
}

export default App;
