import Pagination from "@mui/material/Pagination";

export default function CustomPagination({
  totalItems,
  currentPage,
  onChange,
}) {
  const handlePageChange = (event, newPage) => {
    onChange(newPage);
  };

  return (
    <Pagination
      count={totalItems}
      page={currentPage}
      onChange={handlePageChange}
      variant="outlined"
      color="success"
    />
  );
}