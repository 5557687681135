import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import MKTypography from "components/MKTypography";
import { Box, CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal"; // Importa Fade

export default function CardProductsHome({
  urlImage,
  venture,
  name,
  productId,
}) {
  return (
    <Link to={`products/${productId}`}>
      <Card
        sx={{
          position: "relative",
          filter: "drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.5))",
          backgroundColor: "White",
        }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            image={urlImage}
            alt={name}
            height="250px"
            style={{
              objectFit: "cover",
              width: "50%",
              border: "2px solid #d9d9db",
              filter: "drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.7))",
            }}
          />
          <CardContent
            sx={{
              textAlign: "left",
              position: "absolute",
              bottom: 0,
              background: "rgba(0, 0, 0, 0.0)",
              color: "#ffffff",
              width: "50%",
              height: "100%",
              borderRadius: "12px",
              right: 0,
              padding: 2,
              opacity: 1,
            }}
          >
            
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Fade direction="up">
                <MKTypography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  color="dark"
                  fontWeight="bold"
                  align="center"
                  sx={{
                    fontSize: { xs: "0.9rem", md: "0.9rem", lg: "1rem" },
                  }}
                >
                  {name}
                </MKTypography>
                <MKTypography
                  variant="h2"
                  sx={{
                    fontSize: "1rem",
                  }}
                  align="center"
                  fontWeight="bold"
                  component="div"
                  color="success"
                >
                  {venture}
                </MKTypography>
                </Fade>
              </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}
