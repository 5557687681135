import { useState, useEffect } from "react";

const fetchDataProducts = {
  FetchDataProductsPagination: (currentPage, sizeItems) => {
    const [apiData, setApiData] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/products/${currentPage}/${sizeItems}`
          );

          const data = await response.json();
          setApiData(data.productsData);
          setTotalPage(data.totalPages);
          setTotalItems(data.totalItems);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      fetchDataFromApi();
    }, [currentPage, sizeItems]);

    return { apiData, totalPage, totalItems, loading };
  },

  FetchDataProdById: (id) => {
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/products/${id}`
          );

          const data = await response.json();
          setApiData(data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      fetchDataFromApi();
    }, [id]);

    return { apiData, loading };
  },

  FetchDataProdByVenture: (id) => {
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/products/products-venture/${id}`
          );

          const data = await response.json();
          setApiData(data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      fetchDataFromApi();
    }, [id]);

    return { apiData, loading };
  },

  FetchDataRelatedProducts: (id_emp, id_categ) => {
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchDataFromApi = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/products/related-products/${id_emp}/${id_categ}`
          );

          const data = await response.json();
          setApiData(data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      fetchDataFromApi();
    }, [id_emp, id_categ]);

    return { apiData, loading };
  },
};

export default fetchDataProducts;
