import React, { useEffect, useState } from 'react';

const ProductTotalItems = () => {
  const [totalItems, setTotalItems] = useState(0);
  const [firstProductName, setFirstProductName] = useState('');

  useEffect(() => {
    fetch('https://api.raicesdelbosque.iiap.gob.pe/api/v1/products/1/9999')
      .then(response => response.json())
      .then(data => {
        if (data.totalItems > 0) {
          setTotalItems(data.totalItems);

          if (data.productsData.length > 0) {
            const firstProduct = data.productsData[0]; // Obtén el primer elemento
            setFirstProductName(firstProduct.nombre_prod); // Supongo que el nombre del producto se encuentra en la propiedad 'nombre_prod'
          }
        }
      })
      .catch(error => {
        console.error('Error al obtener el número total de productos y el nombre del primer producto', error);
      });
  }, []);

  return { totalItems, firstProductName };
};

export default ProductTotalItems;
