import React, { useEffect, useState } from 'react';

const ResearchCount = () => {
  const [researchCount, setResearchCount] = useState(0);
  const [firstResearchTitle, setFirstResearchTitle] = useState('');

  useEffect(() => {
    fetch('https://api.raicesdelbosque.iiap.gob.pe/api/v1/researchs')
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          setResearchCount(data.length);

          if (data[0] && data[0].titulo) {
            setFirstResearchTitle(data[0].titulo); // Obtén el título de la primera investigación
          }
        }
      })
      .catch(error => {
        console.error('Error al obtener el número de investigaciones y el título de la primera investigación', error);
      });
  }, []);

  return { researchCount, firstResearchTitle };
};

export default ResearchCount;
