import { useEffect, useState } from 'react';

const CategoryCount = () => {
  const [categoryCount, setCategoryCount] = useState(0);
  const [lastCategoryDescription, setLastCategoryDescription] = useState('');

  useEffect(() => {
    fetch('https://api.raicesdelbosque.iiap.gob.pe/api/v1/categories')
      .then(response => response.json())
      .then(data => {
        const count = data.length; // Contamos los elementos en lugar de obtener el ID más alto
        setCategoryCount(count);
        if (data.length > 0) {
          const firstCategory = data[0]; // Obtén el primer elemento
          setLastCategoryDescription(firstCategory.descripcion);
        }
      })
      .catch(error => {
        console.error('Error al obtener el número de categorías y la descripción del último elemento', error);
      });
  }, []);

  return { categoryCount, lastCategoryDescription };
};

export default CategoryCount;
