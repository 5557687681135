import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copy from '../components/Copyright'

const Template = () => {
  return (
    <>
      <Header/>
      <Outlet></Outlet>
      <Footer />
      <Copy/>
    </>
  );
};

export default Template;
