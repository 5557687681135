// Material Kit 2 React pages
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import Community from "presentation/components/CommunityPage";
import CarouselSection from "presentation/components/HomePage/sections/Carousel";

export default function CommunityPage() {
    return (
        <Box>
            <CarouselSection
                textHeader="Pueblos Indígenas"
                heightHeader="30rem"
            />

            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    backgroundColor: ({
                        palette: { white },
                        functions: { rgba },
                    }) => rgba(white.main, 0.8),
                    backdropFilter: "saturate(200%) blur(30px)",
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <Community />
            </Card>
        </Box>
    );
}
