import Grid from "@mui/material/Grid";
import Chart from "./Chart";
import { Container,Typography } from "@mui/material";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import Diversity1RoundedIcon from "@mui/icons-material/Diversity1Rounded";
import CategoryCount from './APITOTAL/datatotalcategory'; // Importa el componente CategoryCount
import TownID from './APITOTAL/datatotaltowns'; // Importa el componente CategoryCount
import VentureID from './APITOTAL/datatotalventures'; // Importa el componente CategoryCount
import ProductID from './APITOTAL/datatotalproduct'; // Importa el componente CategoryCount
import Research from './APITOTAL/datatotalresearch'; // Importa el componente CategoryCount

function Dashboard() {
  const maxCategoryID = CategoryCount();
  const maxTownID = TownID(); // Usa el nuevo componente
  const maxVentureID = VentureID(); // Usa el nuevo componente
  const totalProductItems = ProductID(); // Usa el nuevo componente
  const totalResearchItems = Research(); // Usa el nuevo componente

  return (
    <Container maxWidth='xs' sx={{ }} >
      <Typography variant="h4" sx={{marginBottom:'20px',textAlign:'center'}}>
      DASHBOARD
      </Typography>
      <Typography variant="h6" sx={{marginBottom:'30px',textAlign:'center',fontSize:'13px',color:'#757575'}}>
      Bienvenido(a) a la Página administrativa de '<spam style={{color:'#107672'}}>Raíces del Bosque</spam>'. Tenemos estos datos para ti:     </Typography>

      <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center'}}>
        <Grid item xs={12} md={5}>
          <Chart
            nombre="Categorias"
            numero={maxCategoryID.categoryCount} // Accede al número de categorías
            linkVerMas="/admin/categories"
            icono={
                <LocalOfferRoundedIcon sx={{color:'red'}}/>
            }
            ultimoItemAgregado={maxCategoryID.lastCategoryDescription}
            />
        </Grid>
        <Grid item xs={12} md={5}>
          <Chart
            nombre="Pueblos"
            numero={maxTownID.maxTownID} // Usa el número de pueblos
            linkVerMas="/admin/towns"
            icono={
                <Diversity1RoundedIcon sx={{color:'green'}}/>
            }
            ultimoItemAgregado={maxTownID.firstTownName}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Chart
            nombre="Emprendimientos"
            numero={maxVentureID.maxVentureID} // Usa el número de empresas
            linkVerMas="/admin/ventures"
            icono={
                <StorefrontRoundedIcon sx={{color:'#fee692'}}/>
            }
            ultimoItemAgregado={maxVentureID.firstVentureName}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Chart
            nombre="Productos"
            numero={totalProductItems.totalItems} // Usa el número total de productos
            linkVerMas="/admin/products"
            icono={
                <ShoppingBagRoundedIcon sx={{color:'#be9787'}}/>
            }
            ultimoItemAgregado={totalProductItems.firstProductName}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Chart
            nombre="Investigaciones"
            numero={totalResearchItems.researchCount}
            linkVerMas="/admin/investigation"
            icono={
                <DescriptionRoundedIcon sx={{color:'#386b69'}}/>
            }
            ultimoItemAgregado={totalResearchItems.firstResearchTitle}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Dashboard;
