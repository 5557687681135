import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Carousel } from "react-responsive-carousel";
import CardProductsHome from "components/CardProductsHome";
import { Fade} from "react-awesome-reveal";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/bg-image.jpg";
import fetchDataProducts from "infraestructura/api/fetchDataProducts";
import _noImage from "assets/images/no-image.png";

const itemsPerPage = 6;

function Catalog() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { apiData, loading } = fetchDataProducts.FetchDataProductsPagination(
    1,
    itemsPerPage
  );

  const products = apiData.map((product) => ({
    ...product,
    src: product.imagenes[0] || _noImage,
  }));

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <MKBox component="section" py={{ xs: 2 }} mb>
      <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{ overflow: "hidden" }}
      >
        <MKBox
          component="img"
          src={bgImage}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          sx={{
            filter: "blur(5px)", // Ajusta el valor del desenfoque según tu preferencia
            opacity: 0.7,
          }}
        />

        <Container sx={{ position: "relative", zIndex: 2, py: 2 }}>
          <Grid
            container
            item
            xs={12}
            md={12}
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            mx="auto"
          >
            <MKTypography
              variant="h2"
              sx={{
                fontSize: {
                  xs: "2rem",
                  sm: "2.7rem",
                  md: "2.5rem",
                },
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                mb: { xs: "20px", md: "0px" },
              }}
              color="white"
              textAlign="center"
            >
              PRODUCTOS
            </MKTypography>
            <MKTypography
              variant="h5"
              color="white"
              textAlign="justify"
              mb={6}
              sx={{
                fontSize: {
                  xs: "1.1rem",
                  sm: "1.1rem",
                  md: "1.1rem",
                  lg: "1..1rem",
                },
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                mt: 4,
                display: { xs: "none", md: "block" },
              }}
            >
              La producción que exhibimos es muy variada y va desde los tejidos
              más tradicionales que tienen una carga de identidad y tradición
              importante, hasta los tejidos y productos innovadores que se han
              adaptado a las preferencias de los diferentes públicos. Una
              producción sostenible y con valor agregado cultural.
            </MKTypography>

            {!isSmallScreen && (
              <Grid container spacing={3} height="auto">
                {products.slice(0, 6).map((product, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={6}
                    lg={4}
                  >
                    <CardProductsHome
                      urlImage={product.src}
                      venture={product.emprendimiento}
                      name={product.nombre_prod}
                      productId={product.id_producto}
                    />
                  </Grid>
                ))}
              </Grid>
            )}

            {isSmallScreen && (
              <Fade>
                <Carousel
                  height="auto"
                  swipeable={false}
                  showThumbs={false}
                  autoPlay
                  interval={5000}
                  infiniteLoop
                  showIndicators={false}
                  showStatus={false}
                  style={{ width: "100%", height: "auto" }}
                >
                  {products.map((product, index) => (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={product.src}
                        alt={`Carousel ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius:'20px'
                        }}
                      />
                    </div>
                  ))}
                </Carousel>
              </Fade>
            )}

            <Grid
              justifyContent="center"
              mt={6}
              mb={2}
              sx={{ textAlign: "center" }}
            >
              <a
                href="/products"
                style={{
                  textDecoration: "none",
                  filter: "drop-shadow(2px 4px 20px rgba(0, 0, 0, 0.5))",
                }}
              >
                <MKButton variant="contained" color="success" size="large">
                  Ver todos los productos
                </MKButton>
              </a>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Catalog;
