import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import CustomPagination from "../../../../components/CustomPagination";
import CircularProgress from "@mui/material/CircularProgress";
import fetchDataProducts from "infraestructura/api/fetchDataProducts";
import _noImage from "assets/images/no-image.png";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, TextField, Typography } from "@mui/material";
import { Bounce, Fade } from "react-awesome-reveal";
import fetchDataCommunity from "infraestructura/api/fetchDataCommunity";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function getCardImages(product) {
  const noImage = _noImage;
  const frontImage = product.imagenes[0] || noImage;
  const backImage =
    product.imagenes.length > 1 ? product.imagenes[1] : frontImage;
  return { frontImage, backImage };
}

function ProducstList() {
  const { apiData: communityData } =
    fetchDataCommunity.FetchDataAllCommunities();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const {
    apiData: productData,
    loading,
    totalItems,
    totalPage,
  } = fetchDataProducts.FetchDataProductsPagination(currentPage, itemsPerPage);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const timeoutId = setTimeout(() => {
      const newFilteredProducts = productData.filter((product) =>
        isMatch(product.nombre_prod, searchTerm)
      );
      setFilteredProducts(newFilteredProducts);
    }, 500);
    setSearchTimeout(timeoutId);
  }, [searchTerm, productData]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "30vh",
        }}
      >
        <CircularProgress color="success" size={60} />
      </div>
    );
  }
  const communityNames = communityData.map((community) => community.nombre);
  const handleSuggestionClick = (firstWord) => {
    handleSearch(firstWord);
  };

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  function isMatch(productName, searchTerm) {
    const normalizedProductName = removeAccents(productName.toLowerCase());
    const normalizedSearchTerm = removeAccents(searchTerm.toLowerCase());
    const lowerCaseProductName = productName.toLowerCase();
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return (
      lowerCaseProductName.includes(lowerCaseSearchTerm) ||
      normalizedProductName.includes(normalizedSearchTerm)
    );
  }

  const handleSearch = (searchTerm) => {
    if (searchTerm === "") {
      setItemsPerPage(9);
    } else {
      setItemsPerPage(1000);
    }
    setSearchTerm(searchTerm);
    setCurrentPage(1);
  };

  return (
    <MKBox component="section" mb={4}>
      <Container maxWidth="lg" fixed sx={{ py: 5 }}>
      <Link to="/">
          <Button sx={{color:'#47618d',fontSize:'1rem'}}>
            <ArrowBackIosIcon /> Atrás
          </Button>
        </Link>
        <MKTypography variant="h2" fontWeight="bold" my={6} align="center">
          Explora nuestros increíbles productos
        </MKTypography>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid item xs={12} md={7}>
            <TextField
              color="success"
              fullWidth
              InputProps={{
                sx: {
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.4)",
                  mb: "1rem",
                  borderRadius:'20px'
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label="Buscar Producto"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Grid>
        </Grid>
        <Typography textAlign='center' variant="h6" sx={{color:'#828da0'}}>SUGERENCIAS:</Typography>
        <Fade direction="down">
        <Grid container sx={{ display: "flex", justifyContent: "center",gap:'20px' }}>
        {communityData.slice().reverse().map((community, index) => {
            const communityName = community.nombre;
            const firstWord = communityName.split(" ")[0]; // Obtén la primera palabra
            return (
              <Grid
                item
                xs={5}
                md={1}
                key={index}
                sx={{ display: "flex", justifyContent: "center", mb: "-1rem" }}
              >
                <Button
                  color="success"
                  variant="outlined"
                  onClick={() => handleSuggestionClick(firstWord)}
                  sx={{
                    border: "1px solid #d9d9db",
                    margin: "1rem",
                    marginBottom: "1rem",
                    color: "#828da0",
                    transition: "transform 0.2s",
                    "&:hover": {
                      transform: "scale(1.15)",
                    },
                    width: "8rem",
                  }}
                >
                  <SearchIcon />
                  {firstWord}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        </Fade>


        <MKTypography
          variant="subtitle2"
          fontWeight="bold"
          mt={6}
          mb={3}
          align="center"
          color="success"
        >
          +{totalItems} productos
        </MKTypography>

        <Grid container spacing={3}>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => {
              const { frontImage, backImage } = getCardImages(product);
              return (
                <Grid key={product.id_producto} item xs={12} sm={6} lg={4}>
                  <Bounce triggerOnce>
                    <RotatingCard>
                      <RotatingCardFront image={frontImage} />

                      <RotatingCardBack
                        image={backImage}
                        title={product.nombre_prod}
                        action={{
                          type: "internal",
                          route: `/products/${product.id_producto}`,
                          label: "Ver detalle",
                        }}
                      />
                    </RotatingCard>
                  </Bounce>
                </Grid>
              );
            })
          ) : (
            <Grid container item xs={12} sx={{ display: "flex" }}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <MKTypography
                  variant="h4"
                  align="center"
                  fontWeight="bold"
                  justifyItems="center"
                >
                  No se encontraron resultados
                </MKTypography>
              </Grid>
              <Grid item xs={12}>
                <img
                  src={_noImage}
                  alt="not found"
                  style={{
                    width: "200px",
                    height: "200px",
                    alignItems: "center",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </Grid>
            </Grid>
          )}

          <Grid
            xs={12}
            item
            container
            style={{
              padding: "32px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomPagination
              totalItems={totalPage}
              currentPage={currentPage}
              onChange={setCurrentPage}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ProducstList;
