import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import MKBox from "components/MKBox";
import CircularProgress from '@mui/material/CircularProgress';
import fetchDataInvestigation from "infraestructura/api/fetchDataInvestigation";


function InvestigationList() {  
  const { apiData, loading } = fetchDataInvestigation.FetchDataAllInvestigation();

  const CustomCard = ({ image, title, link }) => {
    return (
        <Card
            sx={{
                filter: "drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.5))",
                margin: "10px",
            }}
        >
            <CardActionArea href={link}>
                <CardMedia
                    component="img"
                    image={image}
                    alt={title}
                    sx={{ width: "100%", height: "30vh" }}
                />
                <CardContent
                    sx={{
                        width: "100%",
                        height: { sm: "15rem", xl: "10rem" },
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <MKTypography
                        sx={{
                            fontSize: {
                                xs: "0.8rem",
                                sm: "1rem",
                                md: "1rem",
                                lg: "1rem",
                            },
                            textAlign: "center",
                        }}
                        variant="h2"
                        gutterBottom
                        component="div"
                        mt={1}
                    >
                        {title}
                    </MKTypography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
  };

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh" }}>
        <CircularProgress color="success" size={60} />
      </div>
    );
  }

  return (
    <MKBox component="section" py={2} mb={4}>
      <Container>
        <MKTypography variant="h2" fontWeight="bold" my={6} align="center">
          Nuestras Investigaciones
        </MKTypography>

        <Grid item xs={12} sx={{ py: 2 }}>
          <Grid container justifyContent="center" spacing={2}>
            {apiData.map((investigation) => (
              <Grid key={investigation.id_investigacion} item xs={12} md={4}>
                <CustomCard
                  link={`/investigation/${investigation.id_investigacion}`}
                  image={investigation.imagenes[0]}
                  title={investigation.titulo}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default InvestigationList;
