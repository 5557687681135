// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import ProducstList from "./sections/List";
import CarouselSection from "presentation/components/HomePage/sections/Carousel";

function ProductList() {
  return (
    <MKBox>
      <CarouselSection textHeader="Productos" heightHeader="24rem" />

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <ProducstList />
      </Card>
    </MKBox>
  );
}

export default ProductList;
