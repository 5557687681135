import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Confetti from "react-confetti";

const CopyrightComponent = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        backgroundColor: "black",
       
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        style={{ lineHeight: "25px", color: "white" }}
      >
        © {new Date().getFullYear()} Raíces del Bosque, 
        <span
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            marginLeft: "8px",
          }}
          onClick={handleOpen}
        >
          SOCIODIVERSIDAD - IIAP     </span>
      </Typography>

      <Dialog
        PaperProps={{
          style: {
            overflow: "hidden", // Desactiva el desplazamiento vertical y horizontal
          },
        }}
        fullWidth
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{fontSize:'28px'}} textAlign={"center"}>EQUIPO DE TRABAJO</DialogTitle>
        <DialogContent>
          <Confetti
            height={1000}
            width={600}
            recycle={false} // Impide que las partículas vuelvan a aparecer después de caer
          />
          <DialogContentText textAlign={"center"}>
            <Typography>Coordinador de proyecto:</Typography>
            <Typography color='#000'>▹ Manuel Martin Brañas</Typography> <br />
            <Typography>Coordinador de Desarrollo:</Typography>
            <Typography color='#000'>▹Jhon Charlie Martinez Carranza </Typography> <br />
            <Typography>Desarrolladores:</Typography>
            <Typography color='#000'>▹Josue Franco Soria Ponce <br />(Front-end)</Typography>
            <Typography color='#000'>▹Piero Eleví Frías Mori <br />(Back-end)</Typography>
            <Typography color='#000'>▹Anthony Scott Ramirez Sias <br />(Back-end)</Typography>
            <Typography color='#000'>▹Adrian Sol Sol (Diseñador UI)</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default CopyrightComponent;
