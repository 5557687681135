import { useState, useEffect, createContext } from "react";
import axios from "axios";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState({});

  useEffect(() => {
    const authenticateUser = async () => {
      const token = localStorage.getItem("token_raices");

      if (!token) {
          setLoading(false);
          return;
      }

      const config = {
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
          },
      };

      try {
          const { data } = await axios(
              `${process.env.REACT_APP_API_URL}/users/profile`,
              config
          );
          setAuth(data);
      } catch (error) {
          console.log(error.response.data.error);
          setAuth({});
      }

      setLoading(false);
    };

    authenticateUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };

export default AuthContext;
